import React, { useState, useEffect, useContext } from "react";
import { useParams, Redirect } from "react-router-dom";
import { CartContext } from "../context/cart";
import Header from "../components/Header";
import {
  getProductBySlug,
  getProductCommentsOriginalVersion,
  getProductUpsells,
  getProductPacksInner,
} from "../logic/products";
import Price from "../components/Price";
import RatingStars from "../components/RatingStars";
import { t } from "../helpers";
import Button from "../components/Button";
import Packaging from "../components/Packaging";
import RadioGroup from "../components/RadioGroup";
import CONSTANTS from "../constants";
import PromoSticker from "../components/PromoSticker";
import ProductVideo from "../components/product/ProductVideo";
import ProductReviews from "../components/product/ProductReviews";
import ProductIdealFor from "../components/product/ProductIdealFor";
import ProductCarousel from "../components/product/ProductCarousel";
import ProductIngredients from "../components/product/ProductIngredients";
import ProductTutorial from "../components/product/ProductTutorial";
import ProductDescription from "../components/product/ProductDescription";
import ProductMainTitle from "../components/product/ProductMainTitle";
import ProductGourmetSelector from "../components/product/ProductGourmetSelector";
import ProductSubscriptionSelector from "../components/product/ProductSubscriptionSelector";
import ProductUpsells from "../components/product/ProductUpsells";
import { LazyLoad, useVisibilityHook } from "react-observer-api";
import Footer from "../components/Footer";
import Promises from "../components/Promises";
import CurlyLink from "../components/product/CurlyLink.js";
import { visitProduct } from "../logic/analytics";
import PresaleModal from "../components/product/PresaleModal";
import ClubModal from "../components/product/ClubModal";
import ShowMore from "../components/ShowMore";
import { isCurlyProduct } from "@cocunat/cocunat-core-js";
import { sortCommentsByLanguage } from "@cocunat/cocunat-core-js";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import AddToCartModal from "../components/AddToCartModal";

function ProductSingle() {
  let { lang, country, slug } = useParams();
  const { setElement, isVisible } = useVisibilityHook();
  const {
    cart,
    isGourmet,
    promo,
    loading,
    addProduct,
    productsCached,
    updateProductsCached,
    region,
    showClubModal,
  } = useContext(CartContext);

  let [product, setProduct] = useState(null);
  let [productPack, setProductsPack] = useState(null);
  let [productToAdd, setProductToAdd] = useState(null);
  let [redirectTo, setRedirectTo] = useState(null);
  let [comments, setReviews] = useState(null);
  let [selectedSubscription, setSelectedSubscription] = useState(false);
  let [selectedSubscriptionInterval, setSelectedSubscriptionInterval] =
    useState(12);
  let [upsells, setUpsells] = useState([]);
  let [showGourmet, setShowGourmet] = useState(false);

  const [isOpenPresaleModal, setIsOpenPresaleModal] = useState(false);
  const [isOpenClubModal, setIsOpenClubModal] = useState(false);
  const [clubInfo, setClubInfo] = useState(null);
  const [showCartModal, setShowCartModal] = useState(false);
  const [addedProduct, setAddedProduct] = useState(null);

  useEffect(() => {
    if (!loading) {
      if (productsCached.hasOwnProperty(slug)) {
        initProduct(productsCached[slug]);
      } else {
        fetchProductData();
      }
    }
  }, [promo, loading]);

  useEffect(() => {
    if (product) {
      const prevTitle = document.title;
      document.title = "Cocunat - " + product.translation.name;

      return () => {
        document.title = prevTitle;
      };
    }
  }, [product]);

  const fetchProductReviewsData = async (_product) => {
    let packsIds = Object.keys(_product.products_pack);
    let productIdsRelated = [_product.id, ...packsIds];
    let _comment = await getProductCommentsOriginalVersion(
      productIdsRelated,
      lang,
      true
    );
    const sortedComments = sortCommentsByLanguage(_comment.data, lang, "en");

    // Show comments with rating 4 or 5, and not show ATC comments
    let filteredComments = [];
    for (_comment of sortedComments) {
      if (_comment.rating > 3 && !_comment.parent) {
        filteredComments.push(_comment);
      }
      if (filteredComments.length == 5) {
        break;
      }
    }
    setReviews(filteredComments);
  };

  const fetchProductData = async () => {
    let _promo = promo ? promo.code : "";
    let _product = await getProductBySlug(slug, lang, country, _promo);
    initProduct(_product.data);
  };

  const initProduct = (_product) => {
    setProduct(_product);
    setProductToAdd(_product);
    fetchProductReviewsData(_product);
    updateProductsCached([_product]);
    fetchProductUpsells(_product);
    fetchProductPackInner(_product);
    shouldShowGourmet(_product);
    visitProduct(lang, country, _product, region); // Analytics
  };
  const hasSimpleSubscription = (product) => {
    return (
      product.has_subscription &&
      product.prices &&
      product.prices.regular_subscription_price
    );
  };
  const shouldShowGourmet = (_product = product) => {
    if (_product) {
      setShowGourmet(
        !isGourmet &&
          !hasSimpleSubscription(_product) &&
          _product.prices &&
          _product.prices.show_gourmet
      );
    }
  };

  const fetchProductUpsells = async (_product) => {
    if (_product.upsell.length > 0) {
      let _promo = promo ? promo.code : "";
      try {
        let upsells = await getProductUpsells(
          _product.id,
          lang,
          country,
          _promo
        );
        setUpsells([_product, ...upsells.data]);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const fetchProductPackInner = async (_product) => {
    if (_product.products_pack !== {}) {
      let _promo = promo ? promo.code : "";
      try {
        let productsPacks = await getProductPacksInner(
          _product.id,
          lang,
          country,
          _promo
        );
        setProductsPack(productsPacks.data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onChangeSelectSusbcription = (option) => {
    setSelectedSubscription(option.selected);
    setSelectedSubscriptionInterval(option.interval);
  };

  const handlerSetIsOpenAddToCart = (value) => {
    setShowCartModal(value);
    if (value === false) {
      setAddedProduct(null);
      setSelectedSubscription(false);
    }
  };

  function addProductToCart() {
    if (productToAdd.presale) {
      setIsOpenPresaleModal(true);
      return;
    }

    if (showGourmet && showClubModal) {
      setClubInfo({
        product: productToAdd,
        gourmetPrice: productToAdd.prices.gourmet_product_price,
        selectedSubscription: selectedSubscription,
      });
      setIsOpenClubModal(true);
      return;
    }

    if (selectedSubscription === "GOURMET") {
      addProduct(
        productToAdd,
        productToAdd.prices.gourmet_product_price,
        selectedSubscription
      );
    } else if (selectedSubscription === "SIMPLE") {
      addProduct(
        productToAdd,
        productToAdd.prices.regular_subscription_price,
        selectedSubscription,
        selectedSubscriptionInterval
      );
    } else {
      addProduct(productToAdd);
    }
    let _productToAdd = { ...productToAdd };
    _productToAdd.subscriptionType = selectedSubscription;
    setAddedProduct(_productToAdd);
    setShowCartModal(true);
  }

  if (!product) {
    return (
      <>
        <Header />
        <div id="product-single"></div>
      </>
    );
  }

  if (loading) {
    return (
      <>
        <Header />
        <div id="product-single"></div>
      </>
    );
  }

  if (redirectTo) {
    return <Redirect push to={redirectTo} />;
  }

  return (
    <>
      {product.is_productcontainer && (
        <Redirect
          push
          to={`/${lang}-${country}/product/makeup/${product.translation.slug}`}
        />
      )}
      <Header />
      <div id="product-single">
        <section className="product-single">
          <PresaleModal
            isOpenPresaleModal={isOpenPresaleModal}
            setIsOpenPresaleModal={setIsOpenPresaleModal}
            product={product}
          />
          {addedProduct && (
            <AddToCartModal
              isOpen={showCartModal}
              setIsOpen={handlerSetIsOpenAddToCart}
              addedProduct={addedProduct}
            />
          )}

          <ClubModal
            isOpen={isOpenClubModal}
            setIsOpen={setIsOpenClubModal}
            clubInfo={clubInfo}
          />
          <ProductCarousel product={product} />

          <div className="container-small">
            <ProductMainTitle product={product} lang={lang} />

            <div id="summary">
              <ShowMore text={product.translation.summary} maxLength={200} />
            </div>
            <ProductSubscriptionSelector
              show={hasSimpleSubscription(product)}
              product={product}
              selected={selectedSubscription}
              onChange={onChangeSelectSusbcription}
            />

            <ProductGourmetSelector
              show={showGourmet}
              product={product}
              selected={selectedSubscription}
              onChange={setSelectedSubscription}
              gourmetText={region ? region.extra.gourmet_text : ""}
            />
            {!showGourmet && !hasSimpleSubscription(product) && (
              <p>
                <strong style={{ fontSize: 16 }}>
                  {t("actualPrice")}: <Price prices={product.prices} />{" "}
                </strong>
              </p>
            )}

            {upsells &&
              product &&
              !showGourmet &&
              !hasSimpleSubscription(product) &&
              !Object.keys(product.products_pack).length > 0 &&
              upsells.length > 0 && (
                <ProductUpsells
                  show={true}
                  upsells={upsells}
                  product={product}
                  onChange={setProductToAdd}
                />
              )}

            <Button
              style={{ margin: "30px auto", backgroundColor: "black" }}
              size="big"
              type="primary add-to-cart"
              width="wide"
              onClick={addProductToCart}
            >
              {product.presale ? (
                <span>{t("product_presale")}</span>
              ) : (
                <span> {t("add-to-cart")}</span>
              )}
            </Button>

            <Packaging />
          </div>

          <div ref={setElement}>
            {isVisible && (
              <>
                {product.cloudflare_videos &&
                product.cloudflare_videos.uid &&
                product.cloudflare_videos.thumbnail ? (
                  <VideoPlayer
                    videoId={product.cloudflare_videos.uid}
                    thumbnail={product.cloudflare_videos.thumbnail}
                  />
                ) : (
                  <ProductVideo product={product} />
                )}
              </>
            )}
          </div>
          <Promises style={{ justifyContent: "center" }} />

          <ProductDescription product={product} productsPack={productPack} />
          <LazyLoad as="div">
            <ProductIngredients product={product} lang={lang} />
            <ProductTutorial product={product} lang={lang} />
            {isCurlyProduct(product.id) && <CurlyLink />}
            <ProductIdealFor product={product} lang={lang} />
            {comments && (
              <ProductReviews
                product={product}
                comments={comments}
                showViewAllButton={true}
              />
            )}
          </LazyLoad>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default ProductSingle;

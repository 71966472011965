import React from "react";
import RadioGroup from "../RadioGroup";
import Price from "../Price";

function ProductUpsellItem({ option }) {
  return (
    <>
      {Array.isArray(option.products_pack) && (
        <div>
          <ul>
            {option.products_pack.map((product, i) => (
              <li key={i}>
                {product.number} x {product.translation.name}
              </li>
            ))}
          </ul>
          <Price prices={option.prices} />
        </div>
      )}
    </>
  );
}

const ProductUpsells = React.memo(({ product, show, upsells, onChange }) => {
  let options = [];
  for (let i = 0; i < upsells.length; i++) {
    options.push({
      ...upsells[i],
      value: upsells[i].id,
      title: upsells[i].translation.name,
    });
  }
  console.log("Render upsells");
  return (
    <>
      {show && (
        <section id="upsells">
          <RadioGroup
            options={options}
            defaultValue={product.id}
            DescriptionComponent={ProductUpsellItem}
            onChange={(option) => onChange(option)}
          />
        </section>
      )}
    </>
  );
});

export default ProductUpsells;

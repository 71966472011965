import React, { useContext, useState, useEffect } from "react";
import Modal from "../Modal";
import { t } from "../../helpers";
import Button from "../Button";
import { CartContext } from "../../context/cart";
import { useParams, Redirect } from "react-router-dom";
import { formatPrice } from "../../logic/helpers";

const ClubModal = React.memo(({ isOpen, setIsOpen, clubInfo }) => {
  const { lang, country } = useParams();
  const { addProduct, cart, region, setShowClubModal } =
    useContext(CartContext);
  let [redirectTo, setRedirectTo] = useState(null);

  const addToCart = () => {
    addProduct(clubInfo.product);
    setRedirectTo(`/${lang}-${country}/cart`);
  };

  const closeModal = () => {
    setShowClubModal(false);
    setIsOpen(false);
  };

  const addToCartClub = () => {
    addProduct(
      clubInfo.product,
      clubInfo.product.gourmet_product_price,
      "GOURMET"
    );
    setShowClubModal(false);
    setRedirectTo(`/${lang}-${country}/cart`);
  };

  if (redirectTo) {
    return <Redirect push to={redirectTo} />;
  }

  return (
    <>
      {clubInfo && isOpen && (
        <div className="modal-screen">
          <div className="modal-container">
            <button className="close-button" onClick={() => closeModal()}>
              ✕
            </button>
            <div className="modal-content">
              {clubInfo && (
                <div
                  className="modal-presale-content"
                  id="modalClub"
                  style={{ textAlign: "center" }}
                >
                  {clubInfo.selectedSubscription === "GOURMET" ? (
                    <>
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: `${t("ClubModal_t1")}`,
                        }}
                      ></h1>
                      <p>
                        <strong>
                          {region.extra.club_info.best_products.toUpperCase()}
                        </strong>
                      </p>
                      <ol>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: `${t("ClubModal_p1")}`,
                          }}
                        ></li>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: `${region.extra.club_info.flat_rate}`,
                          }}
                        ></li>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: `${region.extra.club_info.vip_pass}`,
                          }}
                        ></li>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: `${t("ClubModal_p4")}`,
                          }}
                        ></li>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: `${t("ClubModal_p5")}`,
                          }}
                        ></li>
                      </ol>
                    </>
                  ) : (
                    <>
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: `${t("ClubModal_ns_t1")}`,
                        }}
                      ></h1>
                      <p>
                        <strong>{t("ClubModal_ns_t2")}</strong>
                      </p>
                      <ol>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: `${region.extra.club_info.best_products}`,
                          }}
                        ></li>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: `${region.extra.club_info.flat_rate}`,
                          }}
                        ></li>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: `${region.extra.club_info.vip_pass}`,
                          }}
                        ></li>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: `${t("ClubModal_ns_p4")}`,
                          }}
                        ></li>
                        <li
                          dangerouslySetInnerHTML={{
                            __html: `${t("ClubModal_ns_p5")}`,
                          }}
                        ></li>
                      </ol>
                    </>
                  )}
                  <Button
                    size="big"
                    type="primary"
                    width="wide"
                    style={{
                      backgroundColor: "black",
                    }}
                    onClick={addToCartClub}
                  >
                    {t("ClubModal_b1", {
                      price: formatPrice(
                        clubInfo.product.prices.gourmet_product_price,
                        cart.totals.currency
                          ? cart.totals.currency.symbol
                          : null,
                        cart.totals.currency
                          ? cart.totals.currency.format
                          : null
                      ),
                    })}
                  </Button>
                  <Button
                    size="big"
                    type="primary blackTxt"
                    width="wide"
                    onClick={addToCart}
                  >
                    {t("ClubModal_b2", {
                      price: formatPrice(
                        clubInfo.product.prices.discounted ||
                          clubInfo.product.prices.price,
                        cart.totals.currency
                          ? cart.totals.currency.symbol
                          : null,
                        cart.totals.currency
                          ? cart.totals.currency.format
                          : null
                      ),
                    })}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default ClubModal;

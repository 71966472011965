import axios from "axios";
import { t, validateNumericOnly } from "../helpers";
import constants from "../constants";
import { leadCreated } from "./analytics";

export function getHeadersWithoutAuth() {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
}

export function inputValidationError(field, value) {
  let rules = {
    email: {
      minLength: 1,
      shouldContain: ["@", "."],
    },
    first_name: {
      minLength: 2,
    },
    last_name: {
      minLength: 2,
    },
    city: {
      minLength: 2,
    },
    address: {
      minLength: 2,
    },
    zip_code: {
      minLength: 1,
    },
    phone: {
      minLength: 5,
      numericOnly: true,
    },
  };
  if (rules.hasOwnProperty(field)) {
    if (
      rules[field].hasOwnProperty("minLength") &&
      value.length < rules[field].minLength
    ) {
      return t("error_min_chars", {
        field: t(field),
        num: rules[field].minLength,
      });
    }

    if (rules[field].hasOwnProperty("shouldContain")) {
      for (let i = 0; i < rules[field].shouldContain.length; i++) {
        if (!(value.indexOf(rules[field].shouldContain[i]) > -1)) {
          return t("error_email_conditions");
        }
      }
    }
    if (rules[field].hasOwnProperty("numericOnly")) {
      if (!validateNumericOnly(value)) {
        return t("error_numeric_only_conditions", { field: t(field) });
      }
    }
  }
  return null;
}

export function addLead(payload) {
  payload["origin"] = "vnext.cocunat.com";
  leadCreated(payload);
  return axios.post(constants.LEADS_URL, payload, getHeadersWithoutAuth());
}

export function formatPrice(amount, symbol = "€", format = "right") {
  if (format === "left") {
    return `${symbol}${amount}`;
  }
  return `${amount}${symbol}`;
}

import React, { useContext, useEffect, useState } from "react";
import { t } from "../../helpers";
import * as amazonPayments from "../../logic/payments/amazonpay";
import useScript from "../../hooks/useScript";
import { useParams } from "react-router-dom";
import { CartContext } from "../../context/cart";

/* global amazon */
function AmazonPayGateway(props) {
  let { lang, country } = useParams();
  const { cart } = useContext(CartContext);
  const [isAmazonReady, setIsAmazonReady] = useState(false);

  useScript("https://static-eu.payments-amazon.com/checkout.js", () => {
    setIsAmazonReady(true);
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function getLocale() {
    let locale = cart.language.toLowerCase() + "_" + cart.country.toUpperCase();
    if (["en_GB", "de_DE", "fr_FR", "it_IT", "es_ES"].includes(locale)) {
      return locale;
    }
    return "en_GB";
  }

  useEffect(() => {
    setLoading(true);
    let isSubscribed = true;
    const initGateway = async () => {
      console.log("Amazon pay");
      let payload = {};
      if (props.isExpress) {
        payload = {
          checkout_review_return_url: `https://cocunat.com/${lang}-${country}/checkout/amazon-pay/review`,
          currency: cart.currency,
        };
      } else {
        payload = {
          checkout_result_return_url: `https://cocunat.com/${lang}-${country}/checkout/amazon-pay`,
          currency: cart.currency,
          total: cart.totals.total,
          address: cart.address,
          cart_id: cart.cart_id,
        };
      }

      let amazonPayApi = await amazonPayments.createAmazonPayCheckoutSession(
        payload
      );
      console.log("======");
      let locale = getLocale();
      amazon.Pay.renderButton("#AmazonPayButton", {
        // set checkout environment
        merchantId: "A2OIE5MKWU4TEC",
        ledgerCurrency: "EUR",
        sandbox: false,
        // customize the buyer experience
        checkoutLanguage: locale,
        productType: "PayAndShip",
        placement: "Cart",
        buttonColor: "Gold",
        // configure Create Checkout Session request
        createCheckoutSessionConfig: {
          // prettier-ignore
          payloadJSON: amazonPayApi.data.payload,
          signature: amazonPayApi.data.signature, // signature generated in step 3
          publicKeyId: amazonPayApi.data.pub_key_id,
        },
      });
      setLoading(false);
    };
    if (isSubscribed && isAmazonReady) {
      try {
        initGateway().catch((err) => {
          setLoading(false);
          console.log(err);
          setError(t("nogateway"));
          props.handlePaymentError();
        });
      } catch (err) {
        setLoading(false);
        console.log(err);
        setError(t("nogateway"));
        props.handlePaymentError();
      }
    }
    return () => (isSubscribed = false);
  }, [isAmazonReady]);

  return (
    <>
      {error && !props.isExpress && (
        <div className="alert alert-danger">⚠ {error}</div>
      )}
      {(!error || error !== t("nogateway")) && (
        <>
          {props.showPayButton && (
            <div>
              <div
                id="AmazonPayButton"
                style={loading ? {} : { height: "54px", margin: "20px auto" }}
              />
            </div>
          )}

          {loading && !props.isExpress && (
            <img
              src="https://global.cocunat.com/icons/loading.gif"
              height="50"
              width="50"
              alt="Loading"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 30,
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export default AmazonPayGateway;

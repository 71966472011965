/* component for a video player with react memo */
import React from "react";

const VideoPlayer = React.memo(({ videoId, thumbnail }) => {
  return (
    <div style={{ position: "relative", paddingTop: "125%" }}>
      <iframe
        src={`https://iframe.videodelivery.net/${videoId}?muted=true&loop=true&autoplay=true&poster=${encodeURI(
          thumbnail
        )}%3Ftime%3D%26height%3D600&controls=false`}
        style={{
          border: "none",
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }}
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowFullScreen={false}
      ></iframe>
    </div>
  );
});

export default VideoPlayer;

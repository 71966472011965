import React from "react";
import { t } from "../../helpers";

const ProductTutorial = React.memo(({ product, isMakeup = false }) => {
  function getTutorialVideo(productId) {
    let videos = {
      1: ["467394999"],
      2: ["467396456"],
      3: ["467395662", "467396074"],
      53234: ["467395662", "467396074"],
      4: ["467395356"],
      53235: ["467395356"],
      5: ["467394593"],
      27: ["467395662"],
      28: ["467396074"],
      29: ["467395356"],
      30: ["467395356"],
      34: ["467396210"],
      10: ["468213815"],
      36: ["468213815"],
      37: ["468213815"],
      50: ["467394999"],
      47: ["467394999"],
      48: ["467394999"],
      49: ["467394999"],
      56: ["467396456"],
      57: ["467396456"],
      58: ["467396456"],
      59: ["467395662"],
      60: ["467395662"],
      61: ["467395356"],
      62: ["467395356"],
      53203: ["468213815"],
      15: ["467394999"],
      16: ["467394999"],
      17: ["467394999"],
      18: ["467394999"],
      19: ["467394999"],
      20: ["467394999"],
      21: ["467394999"],
      22: ["467394999"],
      23: ["467394999"],
      42: ["467394999"],
      43: ["467394999"],
      44: ["467394999"],
      45: ["467394999"],
      46: ["467394999"],
      24: ["467396456"],
      26: ["467396456"],
      25: ["467396456"],
      66: ["467396456"],
      53228: ["560326300"],
      53229: ["560326300"],
      53230: ["560326300"],
      53231: ["560326300"],
      53240: ["631961558"],
      53242: ["631961558"],
      53241: ["631961558"],
      53243: ["631961558"],
      53244: ["631961558"],
      53246: ["625413051"],
      53245: ["625413051"],
      53250: ["631958229"],
      53265: ["637823450"],
      53266: ["637824435"],
      53267: ["637824952"],
    };
    if (productId in videos) {
      return videos[productId][0];
    }
    return null;
  }

  return (
    <>
      {product.translation.tutorial.length > 0 && (
        <section id="how-to-use" className={isMakeup ? "makeup" : undefined}>
          <div className="container-small">
            <h4
              style={{
                fontFamily: "Brown-Light",
                textTransform: "uppercase",
                color: "rgb(255, 255, 255)",
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "30px",
                marginTop: "0px",
              }}
            >
              {t("tutorial-title")}
            </h4>
            {product.translation.tutorial.map((step, i) => {
              return (
                <div className="step" key={"step-" + i}>
                  {step.icon && (
                    <img
                      src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/${step.icon}&width=50&height=50`}
                      alt={`Tutorial step ${i}`}
                      height="50"
                      width="50"
                    />
                  )}
                  <span
                    style={{ display: "block", margin: "10px 0px" }}
                    dangerouslySetInnerHTML={{ __html: step.text }}
                  />
                </div>
              );
            })}
            {getTutorialVideo(product.id) && (
              <div
                style={{
                  paddingBottom: "125%",
                  position: "relative",
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                }}
              >
                <iframe
                  title="tutorial-video"
                  src={`https://player.vimeo.com/video/${getTutorialVideo(
                    product.id
                  )}?autoplay=1&loop=1&autopause=0&muted=1`}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    position: "absolute",
                  }}
                ></iframe>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
});

export default ProductTutorial;

import { createContext, useState, useEffect } from "react";
import { Subscription } from "@cocunat/cocunat-core-js";
import { getAccountSubscriptions, saveSubscription } from "../logic/account";

const AccountContext = createContext(null);

function AccountContextProvider({ children }) {
  const [subscriptions, setSubscriptions] = useState({});
  /**
   * @description Adds the subscription to the subscriptions object using the subscription uuid as key
   */
  function addSubscription(uuid, subscription) {
    setSubscriptions({ ...subscriptions, [uuid]: subscription });
  }

  /**
   * @description Updates the subscription in the subscriptions object using the subscription uuid as key
   */
  function updateSubscription(uuid, subscription, callback) {
    let updatedSubscription = { ...subscriptions[uuid], ...subscription };
    if (subscription.packs_variations === null) {
      delete updatedSubscription.packs_variations;
    }
    setSubscriptions({ ...subscriptions, [uuid]: updatedSubscription });
  }

  function addProduct(uuid, productData) {
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let subscriptionObject = new Subscription({ ...subscription }).addProduct(
      productData
    );
    updateSubscription(subscription.uuid, subscriptionObject);
  }

  function removeProduct(uuid, productId) {
    console.log("removeProduct", uuid, productId);
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let subscriptionObject = new Subscription({
      ...subscription,
    }).removeProduct(productId);
    updateSubscription(subscription.uuid, subscriptionObject);
  }
  function hasProduct(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return false;
    return (
      new Subscription({ ...subscription }).findProductIndex(productId) > -1
    );
  }

  function isNextProduct(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return false;
    return new Subscription({ ...subscription }).isNextProduct(productId);
  }

  function getSubscriptionType(subscriptionType) {
    let subscriptions = Object.values(subscriptions);
    for (let subscription of subscriptions) {
      if (subscription.subscription_type === subscriptionType) {
        return subscription;
      }
    }
    return null;
  }

  function getSubscriptionTotal(uuid) {
    let subscription = subscriptions[uuid];
    if (!subscription) return null;
    return new Subscription({ ...subscription }).total();
  }

  async function fetchSubs() {
    try {
      const _subscriptions = await getAccountSubscriptions();
      let result = {};
      for (let subscription of _subscriptions.data) {
        result[subscription.uuid] = subscription;
      }
      setSubscriptions(result);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchSubs();
  }, []);

  async function patchSubscription(uuid) {
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let response = await saveSubscription(subscription);
    if (response.status === 200) {
      updateSubscription(uuid, { success: true });
    } else {
      updateSubscription(uuid, { success: false });
    }
  }

  return (
    <AccountContext.Provider
      value={{
        updateSubscription,
        hasProduct,
        subscriptions,
        getSubscriptionType,
        addSubscription,
        addProduct,
        removeProduct,
        patchSubscription,
        isNextProduct,
        getSubscriptionTotal,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export { AccountContextProvider, AccountContext };

import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../../context/cart";

const HomeHeroPromo = React.memo((promoContent) => {
  function clickContent() {
    window.location.href = promoContent.link;
  }
  return (
    <>
      <section id="hero-home-desktop">
        <div
          data-slide="desktop-1"
          style={{
            height: 800,
            position: "relative",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url("https://resizing.cocunat.com/r/?quality=90&fit=cover&width=1920&height=1080&image=https://global.cocunat.com/photos/${promoContent.homeHero.desktop.img}")`,
          }}
          onClick={clickContent}
        >
          <div
            style={{
              bottom: "50px",
              position: "absolute",
              textAlign: "center",
              width: "100%",
              margin: "auto",
            }}
          >
            <div>
              <p
                style={{
                  fontFamily: "Brown-Regular",
                  fontSize: "32px",
                  lineHeight: "37px",
                  marginBottom: "35px",
                }}
              >
                {promoContent.homeHero.desktop.text}
              </p>
            </div>
            <div>
              <a
                style={{
                  backgroundColor: "black",
                  color: "rgb(255, 255, 255)",
                  padding: "15px 60px",
                  marginTop: "20px",
                  fontFamily: "Brown-Bold",
                  fontSize: "18px",
                }}
                href={promoContent.link}
              >
                {promoContent.homeHero.button.text}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="hero-home-mobile">
        <div
          style={{
            height: "520px",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url("https://resizing.cocunat.com/r/?quality=90&fit=cover&width=600&height=1067&image=https://global.cocunat.com/photos/${promoContent.homeHero.mobile.img}")`,
          }}
          onClick={clickContent}
        >
          <div
            style={{
              bottom: "50px",
              position: "absolute",
              textAlign: "center",
              width: "100%",
              margin: "auto",
            }}
          >
            <div>
              <p
                style={{
                  fontFamily: "Brown-Regular",
                  fontSize: "20px",
                  lineHeight: "25px",
                  marginTop: "0px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  maxWidth: "300px",
                }}
              >
                {promoContent.homeHero.mobile.text}
              </p>
            </div>
            <div>
              <a
                style={{
                  backgroundColor: "black",
                  color: "rgb(255, 255, 255)",
                  padding: "15px 60px",
                  marginTop: "20px",
                  fontFamily: "Brown-Bold",
                  fontSize: "1em",
                }}
                href={promoContent.homeHero.link}
              >
                {promoContent.homeHero.button.text}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default HomeHeroPromo;

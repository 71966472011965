import axios from "axios";
import { captureException, captureMessage } from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";
import { trackEvent } from "@phntms/react-gtm";
import * as resci from "./pixels/retention-science";
import constants from "../constants";
import { getHeaders } from "../helpers";
import { isClubinCart } from "./cart";
import { zarazEvent, zarazEcommerce } from "./pixels/zaraz";

const noop = () => {};
const validServerEvents = ["atc"];

export function isFirstOrder(email) {
  return axios.post(
    constants.API_BASE_URL + "orders/first-order/",
    { email: email },
    getHeaders()
  );
}

const getTestCode = () => {
  // This is set in testing.
  if (window.configs && window.configs.TEST_EVENT_CODE) {
    return window.configs.TEST_EVENT_CODE;
  }
  return process.env.VUE_APP_TEST_EVENT_CODE;
};

/**
 * Get the advanced manual matching data.
 */
export const getManualMatch = () => {
  try {
    const match = JSON.parse(localStorage.getItem("amm"));
    if (match) {
      return match;
    }
  } catch (err) {
    console.log(err);
  }
};

/*
 * Get Facebook identifiers stored in cookies.
 *
 * Click ID: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters#fbc
 * Browser ID: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters#fbp
 */
export const getFacebookParams = () => {
  try {
    const cookies = document.cookie.split(";");
    const cookieHash = {};

    cookies.forEach((element) => {
      const [key, value] = element.trim().split("=", 2);
      cookieHash[key] = value;
    });

    return {
      fbp: cookieHash._fbp,
      fbc: cookieHash._fbc,
    };
  } catch (err) {
    captureException(err);
  }
};

const getConvertedAmount = (amount, conversionRate) => {
  let convertedAmount = amount * conversionRate;
  return Math.round(convertedAmount * 1e2) / 1e2;
};

async function getEurExchangeRate(currency) {
  if (!currency || currency.length < 3) {
    captureMessage(`Invalid currency ${currency}`);
  }
  let exchangeRate = 1;
  if (currency !== "EUR") {
    let sessionExchangeRate = sessionStorage.getItem(currency);
    if (sessionExchangeRate !== null) {
      exchangeRate = parseFloat(sessionExchangeRate);
    } else {
      let exchangeRateApi = await axios.get(
        `https://workers-utils-api.cocunat.workers.dev/v1/forex/?currency=${currency}`
      );
      exchangeRate = exchangeRateApi.data.EUR;
      sessionStorage.setItem(currency, exchangeRate.toString());
    }
  }
  return exchangeRate;
}

/**
 * https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
 */
export const updateManualMatch = (match) => {
  try {
    const rules = {
      em: (email) => email.trim(),
      fn: (firstname) => firstname.trim().toLowerCase(),
      ph: (phone) => phone.replace(/[^0-9]/g, ""),
    };
    const existing = JSON.parse(localStorage.getItem("amm")) || {};
    const merged = { ...existing, ...match };
    const final = {};

    for (const key in rules) {
      // eslint-disable-next-line no-prototype-builtins
      if (rules.hasOwnProperty(key)) {
        const handler = rules[key];
        if (typeof merged[key] === "string") {
          final[key] = handler(merged[key]);
        }
      }
    }
    localStorage.setItem("amm", JSON.stringify(final));
  } catch (err) {
    console.error(err);
  }
};

/**
 * Send events to the conversion api.
 *
 * Valid requests documented here: https://github.com/cocunat/iaas/conversions
 * To use the Pixel tests, set VUE_APP_TEST_EVENT_CODE to the value on the FB dashboard.
 *
 * @param {String} type should match the end point
 * @param {*} payload
 */
export async function sendServerEvent(type, _payload) {
  if (!validServerEvents.includes(type)) {
    return Promise.reject("Invalid event type");
  }

  const payload = {
    ..._payload,
    env: process.env.NODE_ENV,
    user_data: {
      ...getFacebookParams(),
      ...getManualMatch(),
    },
  };

  const testEventCode = getTestCode();
  if (testEventCode) {
    payload.test_event_code = testEventCode;
  }

  const url = "https://api.cocunat.com/conversions/" + type;
  try {
    const response = await axios.post(url, payload);
    if (response.status >= 300) {
      console.warn("Conversion event notification failed");
    }
  } catch (err) {
    if (err.isAxiosError && err.response) {
      console.log(payload);
    }
    captureException(err);
  }
}

/**
 * TRACKING EVENT: Add to cart
 * @param {*} product
 * @param {*} cart
 * @param {*} orderlines
 * @param {*} region
 */
export async function addToCart(product, cart, orderlines, region) {
  if (product.translation) {
    product.name = product.translation.name;
  }
  if (product.prices) {
    product.price = product.prices.price;
  }
  let lang = localStorage.getItem("lang") || "en";
  const ga = window.ga || noop;
  const fbq = window.fbq || noop;
  const ttq = window.ttq || noop;
  const dataLayer = window.dataLayer || [];
  let promo_code = "";
  try {
    promo_code = cart.promo_code;
  } catch (err) {
    console.log(err);
  }

  let currency = region.currency;
  let exchangeRate = await getEurExchangeRate(currency);
  let product_price_eur = getConvertedAmount(product.price, exchangeRate);

  zarazEcommerce("Product Added", {
    product_id: product.id,
    sku: product.id,
    category: "Cocunat",
    name: product.name,
    brand: "Cocunat",
    variant: product.id,
    price: product.price,
    currency: currency,
    quantity: 1,
    coupon: promo_code,
    position: 1,
  });

  ga("ec:addProduct", {
    id: product.id,
    name: product.name,
    price: product_price_eur,
    quantity: 1,
  });
  ga("ec:setAction", "add", { list: "{{eclist}}" });
  ga("send", "event", "{{eclist}}", "click", "add to cart"); // Send data using an event.

  const eventID = uuidv4();
  fbq(
    "track",
    "AddToCart",
    {
      content_type: "product",
      content_ids: [product.id],
      value: product.price,
      currency: currency,
    },
    { eventID: eventID }
  );

  ttq.track(
    "AddToCart",
    {
      content_type: "product",
      content_ids: [product.id],
      value: product.price,
      currency: currency,
    },
    { eventID: eventID }
  );

  try {
    trackEvent({
      event: "addToCart",
      ecommerce: {
        add: {
          products: [
            {
              name: product.name,
              id: product.id,
              price: product.price,
              quantity: 1,
              currency: currency,
            },
          ],
        },
      },
    });
  } catch (err) {
    console.log(err);
  }

  sendServerEvent("atc", {
    eventID: eventID,
    currency: currency,
    product: {
      id: product.id,
      name: product.name,
      price: product.price,
    },
    url: window.location.href,
  });

  resci.trackAddToCart(product.id, product.name, product_price_eur);

  dataLayer.push({
    event: "addToCart DataLayer",
    ecommerce: {
      currencyCode: currency,
      add: {
        products: [
          {
            name: product.name,
            id: product.id,
            price: product.price,
            quantity: 1,
            currency: currency,
          },
        ],
      },
    },
  });

  zarazEvent("AddToCart", {
    value: product.price,
    name: product.name,
    price: product.price,
    order_quantity: 1,
    currency: currency,
  });
}

/**
 * TRACKING EVENT: Visit product
 * @param {*} lang
 * @param {*} country
 * @param {*} product
 * @param {*} region
 */
export const visitProduct = async (lang, country, product, region = null) => {
  const ga = window.ga || noop;
  const fbq = window.fbq || noop;
  const ttq = window.ttq || noop;

  const dataLayer = window.dataLayer || [];

  let currency = product.prices.currency;
  let exchangeRate = await getEurExchangeRate(currency);
  ga("ec:addProduct", {
    id: product.id,
    name: product.translation.name,
    price: getConvertedAmount(product.prices.price, exchangeRate),
  });
  ga("ec:setAction", "detail");
  fbq("track", "ViewContent", {
    content_type: "product",
    content_ids: [product.id],
    content_name: product.translation.name,
  });

  trackEvent({
    event: "View Content",
    content_name: product.translation.name,
  });

  resci.trackProductViewed(product.id);

  dataLayer.push({
    event: "View Content DataLayer",
    ecommerce: {
      detail: {
        products: [{ id: product.id }],
      },
    },
  });
  let name = product && product.translation ? product.translation.name : "";

  zarazEvent("ViewContent", {
    content_type: "product",
    content_id: product.id,
    content_name: name,
  });

  ttq.track("ViewContent", {
    content_type: "product",
    content_ids: [product.id],
    content_name: name,
  });
};

/**
 * TRACKING EVENT: Visit checkout
 * @param {*} data
 */
export const visitCheckout = async (data, region = null) => {
  console.log("visitCheckout");
  if (!region) {
    captureMessage(`visitCheckout tracking error, no region`);
    return;
  }
  const ga = window.ga || noop;
  const fbq = window.fbq || noop;
  const ttq = window.ttq || noop;

  let currency = region.currency;
  let exchangeRate = await getEurExchangeRate(currency);
  let converted_orderlines = [];

  for (let i = 0; i < data.orderlines.length; i++) {
    let converted_orderline = {
      id: data.orderlines[i].id,
      name: data.orderlines[i].name,
      price: getConvertedAmount(data.orderlines[i].price, exchangeRate),
      quantity: data.orderlines[i].number,
    };
    converted_orderlines.push(converted_orderline);
    ga("ec:addProduct", converted_orderline);
  }

  trackEvent({
    event: "InitiateCheckout",
  });

  ga("ec:setAction", "checkout", { step: 1 });
  fbq("track", "InitiateCheckout");
  ttq.track("InitiateCheckout");

  resci.trackCartView(converted_orderlines);
};

export const visitThanks = async (paidCart) => {
  try {
    let products = [];
    let content_ids = [];
    let totalOrderlines = 0;
    let paidCardfromStorage = JSON.parse(sessionStorage.getItem("paid_cart"));
    //We check if pixelsFired field is in paidCart before firing the events
    if (
      paidCardfromStorage.cart_id === paidCart.cart_id &&
      !paidCardfromStorage.pixelsFired
    ) {
      const ga = window.ga || noop;
      const fbq = window.fbq || noop;
      const ttq = window.ttq || noop;

      const dataLayer = window.dataLayer || [];

      const currency = paidCart.currency || "EUR";
      let exchangeRate = await getEurExchangeRate(currency);
      for (let i = 0; i < paidCart.orderlines.length; i++) {
        products.push({
          id: paidCart.orderlines[i].id,
          name: paidCart.orderlines[i].name,
          price: getConvertedAmount(paidCart.orderlines[i].price, exchangeRate),
          quantity: paidCart.orderlines[i].number,
        });
        content_ids.push(paidCart.orderlines[i].id);
        totalOrderlines += parseInt(paidCart.orderlines[i].number);
      }

      let isFirstOrderResponse = await isFirstOrder(paidCart.address.email);
      let containsClub = isClubinCart(paidCart);
      let eventName = "";
      let eventGTMName = "";
      let eventSubName = null;
      let gaCustomer = {};

      if (isFirstOrderResponse.data.first_order === true) {
        eventName = "new_customer_purchase";
        eventGTMName = "Purchase New";
        gaCustomer = { new: 1 };
        if (containsClub) {
          eventSubName = "new_subscription_purchase";
        }
      } else {
        eventName = "existing_customer_purchase";
        eventGTMName = "Purchase Repeat";
        gaCustomer = { new: 0 };
        if (containsClub) {
          eventSubName = "return_subscription_purchase";
        }
      }

      fbq(
        "track",
        "Purchase",
        {
          value: paidCart.totals.total,
          currency: paidCart.currency,
          content_type: "product",
          content_ids: content_ids,
        },
        { eventID: paidCart.cart_id }
      );
      ga("ec:setAction", "purchase", {
        id: paidCart.cart_id,
        revenue: getConvertedAmount(paidCart.totals.total, exchangeRate),
        shipping: getConvertedAmount(paidCart.totals.shipping, exchangeRate),
        coupon: paidCart.promo_code,
        products: products,
        customer: gaCustomer,
      });

      dataLayer.push({
        event: "Purchase DataLayer",
        ecommerce: {
          purchase: {
            actionField: {
              id: paidCart.cart_id,
              revenue: getConvertedAmount(paidCart.totals.total, exchangeRate),
              shipping: getConvertedAmount(
                paidCart.totals.shipping,
                exchangeRate
              ),
              coupon: paidCart.promo_code,
            },
            products: products,
          },
        },
      });
      ga("send", "event", "Checkout", "purchase");

      let total_eur = getConvertedAmount(paidCart.totals.total, exchangeRate);
      let shipping_cost_eur = getConvertedAmount(
        paidCart.totals.shipping,
        exchangeRate
      );
      ga("ec:setAction", eventName, {
        id: paidCart.cart_id,
        revenue: total_eur,
        shipping: shipping_cost_eur,
        coupon: paidCart.promo_code,
        products: products,
      });

      fbq("track", eventName, {
        value: paidCart.totals.total,
        currency: paidCart.currency,
        content_type: "product",
        content_ids: content_ids,
      });

      if (eventSubName) {
        fbq("track", eventSubName, {
          value: paidCart.totals.total,
          currency: paidCart.currency,
          content_type: "product",
          content_ids: content_ids,
        });
        ttq.track(eventSubName, {
          value: paidCart.totals.total,
          currency: paidCart.currency,
          content_type: "product",
          content_ids: content_ids,
        });
      }

      trackEvent({
        event: "Purchase",
        content_category: "Suscription",
        ecommerce: {
          purchase: {
            actionField: {
              id: paidCart.cart_id,
              revenue: total_eur,
              shipping: shipping_cost_eur,
              coupon: paidCart.promo_code,
            },
            products: products,
          },
        },
      });

      trackEvent({
        event: eventGTMName,
        content_category: "Suscription",
        ecommerce: {
          purchase: {
            actionField: {
              id: paidCart.cart_id,
              revenue: total_eur,
              shipping: shipping_cost_eur,
              coupon: paidCart.promo_code,
            },
            products: products,
          },
        },
      });

      dataLayer.push({
        event: eventGTMName + " DataLayer",
        ecommerce: {
          purchase: {
            actionField: {
              id: paidCart.cart_id,
              revenue: total_eur,
              shipping: shipping_cost_eur,
              coupon: paidCart.promo_code,
            },
            products: products,
          },
        },
      });

      resci.trackCheckoutSuccess(paidCart.cart_id, total_eur, products);

      ttq.track(
        "CompletePayment",
        {
          value: paidCart.totals.total,
          currency: paidCart.currency,
          content_type: "product",
          content_ids: content_ids,
        },
        { eventID: paidCart.cart_id }
      );
      ttq.track(eventName, {
        value: paidCart.totals.total,
        currency: paidCart.currency,
        content_type: "product",
        content_ids: content_ids,
      });

      let zarazOrderlines = [];
      for (let i = 0; i < paidCart.orderlines.length; i++) {
        zarazOrderlines.push({
          product_id: paidCart.orderlines[i].id,
          sku: paidCart.orderlines[i].id,
          name: paidCart.orderlines[i].name,
          price: getConvertedAmount(paidCart.orderlines[i].price, exchangeRate),
          quantity: paidCart.orderlines[i].number,
          category: "Cocunat",
        });
      }
      zarazEcommerce("Order Completed", {
        checkout_id: paidCart.cart_id,
        order_id: paidCart.cart_id,
        affiliation: "cocunat.com",
        total: total_eur,
        revenue: total_eur,
        shipping: shipping_cost_eur,
        tax: 0,
        discount: getConvertedAmount(paidCart.totals.discount, exchangeRate),
        coupon: paidCart.promo_code,
        currency: paidCart.currency,
        products: zarazOrderlines,
      });

      zarazEvent("Purchase", {
        value: paidCart.totals.total,
        currency: paidCart.currency,
        order_quantity: totalOrderlines,
        product_ids: content_ids,
        order_id: paidCart.cart_id,
        promo_code: paidCart.promo_code,
      });

      zarazEvent(eventName, {
        value: paidCart.totals.total,
        currency: paidCart.currency,
        order_quantity: totalOrderlines,
        product_ids: content_ids,
        order_id: paidCart.cart_id,
        promo_code: paidCart.promo_code,
      });
    }

    paidCardfromStorage.pixelsFired = true;
    sessionStorage.setItem("paid_cart", JSON.stringify(paidCardfromStorage));
  } catch (err) {
    console.log(err);
    captureException("Visit Thanks " + err);
  }
};

export const leadCreated = async (data) => {
  const fbq = window.fbq || noop;
  const ttq = window.ttq || noop;
  fbq("track", "Lead", {
    content_name: data.where,
    content_category: "Suscription",
  });

  ttq.track("Lead", {
    content_name: data.where,
    content_category: "Suscription",
  });

  zarazEvent("LeadCreated", {
    lead_type: data.where,
  });
};

export const visitCategory = async (data) => {
  zarazEvent("ViewCategory", {
    category: data,
  });
};

import Carousel from "../Carousel";
import React, { useState } from "react";

const ProductCarousel = React.memo(({ product }) => {
  const [media, setMedia] = useState(null);
  if (product && product.media && product.media.length > 1 && !media) {
    let productMedia = [...product.media];
    if (product.pretty_image) {
      let pretty = { location: "", media: product.pretty_image, order: 0 };
      productMedia.unshift(pretty);
    }
    setMedia(productMedia);
  }

  const getExtension = (url) => {
    let imageType = "image/jpeg";
    let extension = url.split(".").pop();
    if (extension === "png") {
      imageType = "image/png";
    }
    return imageType;
  };

  return (
    <>
      {media ? (
        <Carousel
          controls={true}
          element="carousel-single-product"
          options={{
            startAt: 0,
            perView: 1,
            autoplay: false,
            gap: 0,
            type: "slider",
            peek: 0,
          }}
        >
          {media.map((photo, i) => (
            <picture class="carousel-single-product" key={"media-" + i}>
              <source
                srcSet={`https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=420&height=525&image=https://global.cocunat.com/photos/${photo.media}`}
                type="image/webp"
                height="525"
                width="420"
              />
              <source
                srcSet={`https://resizing.cocunat.com/r/?quality=90&fit=cover&width=420&height=525&image=https://global.cocunat.com/photos/${photo.media}`}
                type={getExtension(photo.media)}
                height="525"
                width="420"
              />
              <img
                src={`https://resizing.cocunat.com/r/?quality=90&fit=cover&width=420&height=525&image=https://global.cocunat.com/photos/${photo.media}`}
                height="525"
                width="420"
                className="custom-loader hero-loading-img"
                alt=""
              />
            </picture>
          ))}
        </Carousel>
      ) : (
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={`https://resizing.cocunat.com/r/?quality=90&fit=contain&width=438&height=375&image=https://global.cocunat.com/photos/${product.image}`}
            layout="responsive"
            data-hero
            className="custom-loader hero-loading-img"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </div>
      )}
    </>
  );
});

export default ProductCarousel;

import React, { useContext, useEffect, useState } from "react";
import { t } from "../helpers";
import { CartContext } from "../context/cart";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HomeCarouselQuotes from "../components/home/HomeCarouselQuotes";
import HomeCarouselInstagram from "../components/home/HomeCarouselInstagram";
import HomeClaims from "../components/home/HomeClaims";
import Promises from "../components/Promises";
import ProductLoop from "../components/product/ProductLoop";
import HomeHeroPromo from "../components/home/HomeHeroPromo";
import HomeHeroDefault from "../components/home/HomeHeroDefault";
import Carousel from "../components/Carousel";
import HomeBanners from "../components/home/HomeBanners";
import { getProductsByCategory } from "../logic/products";
import { LazyLoad } from "react-observer-api";
import { getPromoContent } from "../logic/promotions";
import AddToCartModal from "../components/AddToCartModal";

const getProducts = async (lang, country, promo) => {
  let homeProducts = await getProductsByCategory("home", lang, country, promo);
  let bestSellers = await getProductsByCategory(
    "best-sellers",
    lang,
    country,
    promo
  );

  let products = await Promise.all([homeProducts.data, bestSellers.data]);
  return products;
};

function Home() {
  const { region, promo, updateProductsCached, loading } =
    useContext(CartContext);
  const [products, setProducts] = useState([[], []]);
  let { lang, country } = useParams();
  let [promoContent, setPromoContent] = useState(null);
  const [isOpenAddToCart, setIsOpenAddToCart] = useState(false);
  const [addedProduct, setAddedProduct] = useState(null);

  const showAddToCart = (product) => {
    setAddedProduct(product);
    setIsOpenAddToCart(true);
  };

  const handlerSetIsOpenAddToCart = (value) => {
    setIsOpenAddToCart(value);
    if (value === false) {
      setAddedProduct(null);
    }
  };

  async function fetchData() {
    let _promo = promo ? promo.code : "";
    try {
      const products = await getProducts(lang, country, _promo);
      setProducts(products);
      if (products.length === 2) {
        updateProductsCached([...products[0], ...products[1]]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (region) {
      let promoCode = promo && promo.code ? promo.code : null;
      let content = getPromoContent(region, promoCode);
      setPromoContent(content);
    }
  }, [region, promo]);

  useEffect(() => {
    fetchData();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [promo]);

  if (loading) {
    return <p></p>;
  }

  return (
    <>
      <Header />
      <main id="home-page">
        {promoContent && promoContent.homeHero ? (
          <HomeHeroPromo {...promoContent} />
        ) : (
          <HomeHeroDefault lang={lang} />
        )}
        {addedProduct && (
          <AddToCartModal
            isOpen={isOpenAddToCart}
            setIsOpen={handlerSetIsOpenAddToCart}
            addedProduct={addedProduct}
          />
        )}
        <div className="container-wide">
          <h1 dangerouslySetInnerHTML={{ __html: t("home-title") }} />
          <section className="product-list-mobile">
            {products[0].length > 0 && (
              <Carousel
                element="carousel-products-home"
                options={{
                  startAt: 1,
                  perView: 1,
                  autoplay: 200000,
                  gap: 20,
                  type: "slider",
                  peek: 50,
                }}
              >
                {products[0].map((product, i) => (
                  <ProductLoop
                    product={product}
                    key={i}
                    featured
                    showAddToCartModal={() => showAddToCart(product)}
                  />
                ))}
              </Carousel>
            )}
          </section>

          <section className="product-list-desktop">
            {products[0].map((product, i) => (
              <ProductLoop
                product={product}
                key={i}
                featured
                showAddToCartModal={() => showAddToCart(product)}
              />
            ))}
          </section>

          <a
            className="btn btn-transparent"
            style={{
              width: "290px",
              display: "block",
              margin: "50px auto auto auto",
              color: "black",
              borderColor: "black",
              borderWidth: "1px",
              borderStyle: "solid",
              textTransform: "none",
              textAlign: "center",
              fontSize: "20px",
              fontFamily: "Brown-Regular",
              padding: "12px 0px",
              clear: "both",
            }}
            href={`/${lang}-${country}/search/`}
          >
            {t("all-products")}
          </a>

          <Promises style={{ justifyContent: "center" }} />

          <h4
            className="text-align-center"
            style={{ margin: "35px 0px 25px 0px" }}
          >
            {t("shop_by_range")}
          </h4>

          <HomeBanners />
        </div>
        <LazyLoad as="div">
          <HomeClaims />

          <section
            className="featured-products-1 container-wide"
            style={{ overflow: "hidden" }}
          >
            <p
              style={{
                backgroundImage:
                  "url(https://global.cocunat.com/lightning.png)",
                fontSize: "30px",
                margin: "30px auto",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                fontFamily: "Brown-Bold",
                textAlign: "center",
                backgroundPosition: "center",
                paddingTop: "20px",
                fontWeight: "bold",
              }}
            >
              {t("best_sellers")}
            </p>
            <div className="row">
              {products[1].map((product, i) => (
                <ProductLoop
                  product={product}
                  key={i}
                  showAddToCartModal={() => showAddToCart(product)}
                />
              ))}
            </div>
            <a
              className="btn btn-transparent"
              style={{
                width: "290px",
                display: "block",
                margin: "25px auto 80px auto",
                color: "black",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                textTransform: "none",
                textAlign: "center",
                fontSize: "20px",
                fontFamily: "Brown-Regular",
                padding: "12px 0px",
                clear: "both",
              }}
              href={`/${lang}-${country}/search/`}
            >
              {t("all-products")}
            </a>
          </section>
          <div className="container-wide" style={{ padding: "0px" }}>
            <section id="wow-section" style={{}}>
              <h4
                style={{
                  fontSize: "29px",
                  textTransform: "none",
                  maxWidth: "250px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                  marginTop: "0px",
                }}
              >
                {t("wow_title")}
              </h4>
              <img
                src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/wow.png&width=225&height=131"
                height="131"
                width="225"
                alt="wow"
                loading="lazy"
              />
              <p style={{ marginBottom: "0px" }}>{t("wow_subtitle")}</p>
              <ul style={{ listStyleType: "none" }}>
                <li>{t("wow_1")}</li>
                <li>{t("wow_2")}</li>
                <li>{t("wow_3")}</li>
                <li>{t("wow_4")}</li>
                <li>{t("wow_5")}</li>
                <li>{t("wow_6")}</li>
                <li className="wow-li-short">{t("wow_7")}</li>
              </ul>
            </section>
          </div>
          <section className="container-wide" style={{ paddingBottom: 50 }}>
            <p
              style={{
                fontSize: "29px",
                fontFamily: "Brown-Bold",
                margin: "45px 0px 15px",
                lineHeight: "32px",
                textAlign: "center",
              }}
            >
              {t("press_title")}
            </p>
            <HomeCarouselQuotes type="carousel" perView="3" />
            <HomeCarouselQuotes type="slider" perView="1" />
          </section>
          <section
            className="instagram-carousel"
            style={{
              background: "#fee4de",
              padding: "25px 0 30px",
              textAlign: "center",
            }}
          >
            <div className="container-wide">
              <p
                style={{
                  fontSize: "29px",
                  fontFamily: "Brown-Bold",
                  margin: "8px 0px 15px 0px",
                  lineHeight: "32px",
                }}
              >
                {t("home_ig_title")}
              </p>
              <p
                className="instagram-name"
                dangerouslySetInnerHTML={{ __html: t("ig_follow") }}
              ></p>

              <HomeCarouselInstagram type="carousel" perView="3" />
              <HomeCarouselInstagram type="slider" perView="1" />
            </div>
          </section>
          <section
            className="revolution"
            style={{
              width: "100%",
              margin: "40px auto 40px auto",
              textAlign: "center",
              maxWidth: "1000px",
            }}
          >
            <div>
              <div className="revolution-image" style={{ flex: 1 }}>
                <img
                  src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/better.jpg&width=365&height=352"
                  height="352"
                  width="365"
                  alt="revolution"
                  loading="lazy"
                />
              </div>
            </div>
            <div
              style={{
                padding: "24px",
                flex: 1,
                paddingleft: "60px",
                paddingTop: "0px",
              }}
              className="revolution-text"
            >
              <h1
                dangerouslySetInnerHTML={{ __html: t("home_revolution") }}
                style={{
                  color: "black",
                  padding: "0px",
                  fontFamily: "Brown-Regular",
                  margin: "auto",
                  fontSize: "30px",
                  lineHeight: "1.125",
                }}
              ></h1>
              <p style={{ fontSize: "16px", lineHeight: "24px" }}>
                <s
                  dangerouslySetInnerHTML={{
                    __html: t("home_revolution_pink"),
                  }}
                ></s>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("home_revolution_text"),
                  }}
                ></span>
              </p>
              <div>
                <a
                  className="btn btn-transparent"
                  style={{
                    width: "290px",
                    display: "block",
                    margin: "auto",
                    color: "black",
                    border: "1px solid black",
                    textTransform: "none",
                    fontSize: "16px",
                    padding: "15px",
                    fontFamily: "Brown-Regular",
                  }}
                  href={t("home_welcome_link")}
                >
                  <strong>{t("home_welcome_cocunat")}</strong>
                </a>
              </div>
            </div>
          </section>
          <section className="no-nasties-mobile">
            <div className="container-cocunat">
              <p>
                <img
                  src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/no-nasties-no-lies-new.png&width=230&height=117"
                  height="117"
                  width="230"
                  alt="revolution"
                />
              </p>
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "36px",
                  margin: "15px 0px",
                }}
              >
                <strong>{t("no_toxics")}</strong>
              </p>
            </div>
          </section>
          <section className="no-nasties-desktop">
            <div className="image">
              <img
                src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/planta-footer.png&width=450&height=600"
                height="600"
                width="450"
                loading="lazy"
                alt="revolution"
              />
            </div>
            <div className="content">
              <p>
                <img
                  src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/no-nasties-no-lies-new.png&width=230&height=117"
                  height="117"
                  width="230"
                  loading="lazy"
                  alt="revolution"
                />
              </p>
              <p>
                <strong>{t("no_toxics")}</strong>
              </p>
            </div>
          </section>
        </LazyLoad>
      </main>
      <Footer />
    </>
  );
}

export default Home;

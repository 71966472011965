import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "./css/index.css";
import Home from "./screens/Home";
import Cart from "./screens/Cart";
import Search from "./screens/Search";
import { CartContext, CartContextProvider } from "./context/cart";
import ProductSingle from "./screens/ProductSingle";
import ProtectedRoute from "./components/ProtectedRoute";
import { getURLParams, urlToLocale } from "./helpers";
import { getCountryRegion } from "./logic/regions";
import "./css/index.css";
import { AccountContext, AccountContextProvider } from "./context/account";
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { captureException } from "@sentry/browser";

const enviroment = process.env.REACT_APP_ENV || "live";

if (enviroment) {
  Sentry.init({
    dsn: "https://fd24ca8d56ed468483ccbb24367f5119@o127200.ingest.sentry.io/6146982",
    integrations: [new TracingIntegrations.BrowserTracing()],
    environment: enviroment,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

const Error404 = React.lazy(() => import("./screens/Error404"));
const Login = React.lazy(() => import("./screens/Login"));
const Signup = React.lazy(() => import("./screens/Signup"));
const Checkout = React.lazy(() => import("./screens/Checkout"));
const CheckoutAddress = React.lazy(() => import("./screens/CheckoutAddress"));
const PreCheckout = React.lazy(() => import("./screens/PreCheckout"));
const SubscriptionUpsells = React.lazy(() =>
  import("./screens/account/subscriptions/SubscriptionUpsells")
);
const SubscriptionRefill = React.lazy(() =>
  import("./screens/account/subscriptions/SubscriptionRefill")
);
const CheckoutAmazonPay = React.lazy(() =>
  import("./screens/CheckoutAmazonPay")
);

const CheckoutAmazonPayReview = React.lazy(() =>
  import("./screens/CheckoutAmazonPayReview")
);
const CheckoutAddressSelect = React.lazy(() =>
  import("./screens/CheckoutAddressSelect")
);
const CheckoutError = React.lazy(() => import("./screens/CheckoutError"));
const Thanks = React.lazy(() => import("./screens/Thanks"));
const Account = React.lazy(() => import("./screens/account/Account"));
const AccountOrders = React.lazy(() =>
  import("./screens/account/AccountOrders")
);
const AccountPaymentMethods = React.lazy(() =>
  import("./screens/account/AccountPaymentMethods")
);
const AccountNewPaymentMethod = React.lazy(() =>
  import("./screens/account/AccountNewPaymentMethod")
);
const AccountResetPassword = React.lazy(() =>
  import("./screens/account/AccountResetPassword")
);
const AccountAddresses = React.lazy(() =>
  import("./screens/account/AccountAddresses")
);
const AccountAddressesSingle = React.lazy(() =>
  import("./screens/account/AccountAddressesSingle")
);

const AccountInformation = React.lazy(() =>
  import("./screens/account/AccountInformation")
);
const AccountSubscriptions = React.lazy(() =>
  import("./screens/account/AccountSubscriptions")
);
const AccountSubscriptionsSingle = React.lazy(() =>
  import("./screens/account/AccountSubscriptionsSingle")
);

const AccountBalances = React.lazy(() =>
  import("./screens/account/AccountBalances")
);

const ProductSingleReviews = React.lazy(() =>
  import("./screens/ProductSingleReviews")
);
const ContentSingle = React.lazy(() => import("./screens/ContentSingle"));

const ProductSingleMakeup = React.lazy(() =>
  import("./screens/ProductSingleMakeup")
);

const RefillSubscriptions = React.lazy(() =>
  import("./screens/RefillSubscriptions")
);

const CartAddProduct = React.lazy(() => import("./screens/CartAddProduct"));
const CartAddMultiProducts = React.lazy(() =>
  import("./screens/CartAddMultiProducts")
);

const LandingExperienceGourmet = React.lazy(() =>
  import("./screens/LandingExperienceGourmet")
);

global.Promise = require("promise");

require("promise/lib/rejection-tracking").enable({
  allRejections: true,
  onUnhandled: (id, error) => {
    console.error(error);
    captureException(error);
  },
});

function RouteMiddleware() {
  const location = useLocation();
  const {
    cart,
    updateCartAttributes,
    language,
    setLanguage,
    region,
    setRegion,
    applyCartPromo,
  } = useContext(CartContext);

  useEffect(() => {
    const [lang, country] = urlToLocale(location.pathname);

    if (language !== lang) {
      setLanguage(lang);
    }

    if (!region) {
      getCountryRegion(country)
        .then((response) => {
          if (response.status === 200) {
            setRegion(response.data);
          }
        })
        .catch(() => {});
    }
  }, [location]);

  useEffect(() => {
    if (cart) {
      const country = urlToLocale(location.pathname)[1];
      if (country !== cart.country) {
        updateCartAttributes({ country: country });
      }

      const searchParams = getURLParams(location.search);
      let promo_param =
        searchParams.promo || searchParams.promocion || searchParams.promotion;
      if (promo_param && promo_param !== cart.promo_code) {
        applyCartPromo(promo_param);
      }
      if (
        searchParams.utm_medium ||
        searchParams.utm_source ||
        searchParams.utm_campaign
      ) {
        localStorage.setItem(
          "utms",
          JSON.stringify({
            utm_medium: searchParams.utm_medium,
            utm_source: searchParams.utm_source,
            utm_campaign: searchParams.utm_campaign,
          })
        );
      }
    }
  }, [location, cart]);

  return <></>;
}

function AppRouter() {
  useEffect(() => {
    function amm() {
      try {
        var s, d;
        s = localStorage.getItem("amm");
        d = JSON.parse(s);
        if (d) {
          return d;
        }
      } catch (err) {
        console.log(err);
      }
    }

    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "1983837231849232", amm());
    fbq("track", "PageView");

    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      "script",
      "https://www.google-analytics.com/analytics.js",
      "ga"
    );

    ga("create", "UA-42631937-5", "auto");
    ga("require", "GTM-NJNWVNZ");
    ga("require", "ec");
    document.addEventListener("DOMContentLoaded", function () {
      ga("send", "pageview");
    });

    !(function (w, d, t) {
      w.TiktokAnalyticsObject = t;
      var ttq = (w[t] = w[t] || []);
      (ttq.methods = [
        "page",
        "track",
        "identify",
        "instances",
        "debug",
        "on",
        "off",
        "once",
        "ready",
        "alias",
        "group",
        "enableCookie",
        "disableCookie",
      ]),
        (ttq.setAndDefer = function (t, e) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        });
      for (var i = 0; i < ttq.methods.length; i++)
        ttq.setAndDefer(ttq, ttq.methods[i]);
      (ttq.instance = function (t) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      }),
        (ttq.load = function (e, n) {
          var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
          (ttq._i = ttq._i || {}),
            (ttq._i[e] = []),
            (ttq._i[e]._u = i),
            (ttq._t = ttq._t || {}),
            (ttq._t[e] = +new Date()),
            (ttq._o = ttq._o || {}),
            (ttq._o[e] = n || {});
          n = document.createElement("script");
          (n.type = "text/javascript"),
            (n.defer = !0),
            (n.src = i + "?sdkid=" + e + "&lib=" + t);
          e = document.getElementsByTagName("script")[0];
          e.parentNode.insertBefore(n, e);
        });

      ttq.load("C8770CGJVRJHAP8GSAH0");
      ttq.page();
    })(window, document, "ttq");

    (function () {
      window._rsq = window._rsq || [];
      var rScix = document.createElement("script");
      rScix.type = "text/javascript";
      rScix.defer = true;
      rScix.src =
        ("https:" === document.location.protocol ? "https://" : "http://") +
        "d1stxfv94hrhia.cloudfront.net/waves/v3/w.js";
      document.getElementsByTagName("body")[0].appendChild(rScix);
    })();

    (function () {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://182114.tracking.hyros.com/v1/lst/universal-script?ph=55518a23778d3981fb75ba8a5956c64dbd125a52aab869d88e7c13cd0fc0c75e&tag=!tracking";
      script.defer = true;
      document.getElementsByTagName("body")[0].appendChild(script);
    })();

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.defer = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-KW3SVVL");
  }, []);
  return (
    <CartContextProvider>
      <React.Suspense fallback={<span></span>}>
        <Router>
          <RouteMiddleware />
          <Switch>
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/search/:categoryId"
              component={Search}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/search"
              component={Search}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/refill/:subscriptionType"
              component={RefillSubscriptions}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/cart/add"
              component={CartAddMultiProducts}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/cart/:productId/:subscriptionType"
              component={CartAddProduct}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/cart/:productId"
              component={CartAddProduct}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/cart"
              component={Cart}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/checkout/address/select"
              component={CheckoutAddressSelect}
            />
            <Route
              path={`/:lang([a-z]{2})-:country([a-z]{2})/landing/cocunat-club`}
              component={LandingExperienceGourmet}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/checkout/pre"
              component={PreCheckout}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/checkout/address"
              component={CheckoutAddress}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/checkout/amazon-pay/review"
              component={CheckoutAmazonPayReview}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/checkout/amazon-pay"
              component={CheckoutAmazonPay}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/checkout/thanks"
              component={Thanks}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/checkout/error"
              component={CheckoutError}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/checkout"
              component={Checkout}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/product/:slug/reviews"
              component={ProductSingleReviews}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/product/makeup/:slug"
              component={ProductSingleMakeup}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/product/:slug"
              component={ProductSingle}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/account"
              render={(props) => (
                <>
                  <AccountContextProvider>
                    <React.Suspense fallback={<span></span>}>
                      <ProtectedRoute
                        {...props}
                        path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/:type/:uuid/upsells"
                        component={SubscriptionUpsells}
                      />
                      <ProtectedRoute
                        {...props}
                        path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/:type/:uuid/refill"
                        component={SubscriptionRefill}
                      />
                      <ProtectedRoute
                        {...props}
                        path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/:type/:uuid/"
                        component={AccountSubscriptionsSingle}
                        exact
                      />
                      <ProtectedRoute
                        {...props}
                        path={`/:lang([a-z]{2})-:country([a-z]{2})/account/`}
                        component={Account}
                        exact
                      />
                    </React.Suspense>
                  </AccountContextProvider>
                </>
              )}
            />
            <ProtectedRoute
              path="/:lang([a-z]{2})-:country([a-z]{2})/profile/orders"
              component={AccountOrders}
            />
            <ProtectedRoute
              path="/:lang([a-z]{2})-:country([a-z]{2})/profile/payment_methods/new"
              component={AccountNewPaymentMethod}
            />
            <ProtectedRoute
              path="/:lang([a-z]{2})-:country([a-z]{2})/profile/payment_methods"
              component={AccountPaymentMethods}
            />
            <ProtectedRoute
              path="/:lang([a-z]{2})-:country([a-z]{2})/profile/addresses/:addressId"
              component={AccountAddressesSingle}
            />
            <ProtectedRoute
              path="/:lang([a-z]{2})-:country([a-z]{2})/profile/addresses"
              component={AccountAddresses}
            />
            <ProtectedRoute
              path="/:lang([a-z]{2})-:country([a-z]{2})/profile/information"
              component={AccountInformation}
            />
            <ProtectedRoute
              path="/:lang([a-z]{2})-:country([a-z]{2})/profile/subscriptions"
              component={AccountSubscriptions}
            />
            <ProtectedRoute
              path="/:lang([a-z]{2})-:country([a-z]{2})/profile/balances"
              component={AccountBalances}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/resetpassword/:jwt"
              component={AccountResetPassword}
            />

            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/content/:slug"
              component={ContentSingle}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/login"
              component={Login}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/signup"
              component={Signup}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/"
              component={Home}
            />
            <Route path="/" component={Error404} />
          </Switch>
        </Router>
      </React.Suspense>
    </CartContextProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById("root")
);

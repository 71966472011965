import React, { useContext, useEffect, useState } from "react";
import { saveCart } from "../../logic/cart";
import * as braintreePayments from "../../logic/payments/braintree";
import { t } from "../../helpers";
import { CCValidationError } from "../../logic/payments/errors";
import { getFacebookParams } from "../../logic/analytics";
import { CartContext } from "../../context/cart";
import { userLoggedIn } from "../../logic/account";
import CONSTANTS from "../../constants";
import {
  BraintreePayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

const PaypalButton = (props) => {
  const { cart, updateCartAttributes } = useContext(CartContext);
  const [error, setError] = useState(null);
  const [{ isPending, isRejected }] = usePayPalScriptReducer();

  const [isLoading, setIsLoading] = useState(false);

  function getLocale() {
    return cart.language.toLowerCase() + "_" + cart.country.toUpperCase();
  }
  function getAddressFromPaypal(payload) {
    let address = { ...cart.address };
    const user = userLoggedIn();
    if (user) {
      address.email = user.email;
      address.first_name = user.first_name;
      address.last_name = user.last_name;
    } else {
      address.first_name = payload.details.firstName;
      address.last_name = payload.details.lastName;
      address.email = payload.details.email;
    }
    address.address = payload.details.shippingAddress.line1;
    if (payload.details.shippingAddress.line2) {
      address.address += " " + payload.details.shippingAddress.line2;
    }
    address.zip_code = payload.details.shippingAddress.postalCode;
    address.city = payload.details.shippingAddress.city;
    address.prefix_phone = payload.details.prefix || "";
    address.phone = payload.details.phone || "";
    return address;
  }
  async function saveAndPay(cartCopy, payload) {
    await saveCart(cartCopy, {
      paypal: payload.nonce,
      ...getFacebookParams(),
    });
    try {
      setIsLoading(true);
      await braintreePayments.paymentPaypal(payload.nonce, cartCopy);
    } catch (error) {
      if (error instanceof CCValidationError) {
        setError(t("ccFailed"));
      } else {
        setError(t("nopaypal"));
        props.handlePaymentError();
      }
      return;
    }
    props.handlePaymentSuccess();
  }
  async function handlePayment(payload) {
    console.log("Payment Successful", payload);
    if (props.isExpress) {
      let address = getAddressFromPaypal(payload);
      updateCartAttributes(
        { address: address, gateway: CONSTANTS.GATEWAYS.Paypal },
        async (updatedCart) => {
          await saveAndPay(updatedCart, payload);
        }
      );
    } else {
      await saveAndPay(cart, payload);
    }
  }
  function getPaymentData() {
    console.log("getPaymentData", cart.totals.total);
    let paymentData = {
      flow: "checkout", // Required
      amount: cart.totals.total, // Required
      currency: cart.currency, // Required, must match the currency passed in with loadPayPalSDK
      locale: getLocale(),
      intent: "capture", // Must match the intent passed in with loadPayPalSDK
      enableShippingAddress: true,
      shippingAddressEditable: false,
    };
    if (cart.address && !props.isExpress) {
      const address = cart.address;
      paymentData.shippingAddressOverride = {
        recipientName: address.first_name + " " + address.last_name,
        line1: address.address,
        city: address.city,
        countryCode: address.country.toUpperCase(),
        postalCode: address.zip_code,
        phone: address.phone,
      };
    }
    return paymentData;
  }
  const createOrderCallback = (data, actions) => {
    return actions.braintree.createPayment(getPaymentData()).then((orderId) => {
      // Your code here after create the order
      return orderId;
    });
  };
  const onApproveCallback = (data, actions) => {
    return actions.braintree.tokenizePayment(data).then((payload) => {
      return handlePayment(payload);
    });
  };
  const onErrorCallback = (err) => {
    console.error("PayPal error", err);
    setError(t("nopaypal"));
    props.handlePaymentError();
  };

  return (
    <>
      {(error || (isRejected && !props.isExpress)) && (
        <div className="alert alert-danger">
          ⚠ {error ? error : t("nogateway")}
        </div>
      )}
      {(!error || error !== t("nogateway")) && (
        <BraintreePayPalButtons
          style={{ marginTop: "40px" }}
          disabled={false}
          fundingSource="paypal" // Available values are: ["paypal", "card", "credit", "paylater", "venmo"]
          forceReRender={[cart?.totals?.total]}
          createOrder={createOrderCallback}
          onApprove={onApproveCallback}
          onError={onErrorCallback}
        />
      )}
      {((isPending && !props.isExpress) || isLoading) && (
        <img
          src="https://global.cocunat.com/icons/loading.gif"
          height="50"
          width="50"
          alt="Loading"
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 30,
          }}
        />
      )}
    </>
  );
};

function PaypalGateway(props) {
  const { cart } = useContext(CartContext);
  const [clientToken, setClientToken] = useState(false);
  useEffect(() => {
    let isSubscribed = true;
    const initGateway = async () => {
      let response = await braintreePayments.getClientToken(cart.currency);
      setClientToken(response.data.client_token);
    };
    if (isSubscribed) {
      try {
        initGateway()
          .then()
          .catch((err) => {
            console.log(err);
            // setError(t("nogateway"));
            props.handlePaymentError();
          });
      } catch (err) {
        console.log(err);
        // setError(t("nogateway"));
        props.handlePaymentError();
      }
    }
    return () => (isSubscribed = false);
  }, []);

  return (
    <>
      <div
        className={props.showPayButton ? "show" : "hide"}
        style={{ margin: "20px auto" }}
      >
        {clientToken && (
          <PayPalScriptProvider
            options={{
              "client-id": braintreePayments.CLIENT_ID,
              "data-client-token": clientToken,
              intent: "capture",
              currency: cart.currency,
            }}
          >
            <PaypalButton {...props} />
          </PayPalScriptProvider>
        )}
      </div>
    </>
  );
}

export default PaypalGateway;

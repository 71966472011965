import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../context/cart";
import { Link, useParams, useLocation } from "react-router-dom";
import Button from "./Button";
import { t } from "../helpers";
import PromoTopBanner from "./PromoTopBanner";

const Header = React.memo(() => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const { cart, isGourmet, region } = useContext(CartContext);
  let { lang, country } = useParams();

  useEffect(() => {
    if (showMenu) {
      setShowMenu(false);
    }
  }, [location]);

  return (
    <>
      <header>
        <PromoTopBanner />
        <div className="container">
          <button onClick={() => setShowMenu(true)} id="open-menu-mobile">
            <img
              src="https://global.cocunat.com/menu-bars.png"
              alt="Open menu"
              width="26"
              height="24"
              decoding="async"
            />
          </button>
          <Link to={`/${lang}-${country}/`}>
            <img
              decoding="async"
              alt="Cocunat"
              height="22"
              width="143"
              src="https://resizing.cocunat.com/r/?quality=100&amp;fit=cover&amp;image=https://global.cocunat.com/photos/logo.png&amp;width=143&amp;height=22"
            />
          </Link>
          <Link
            to={`/${lang}-${country}/account`}
            style={{ position: "absolute", top: 9, right: 50 }}
          >
            <img
              decoding="async"
              alt="Cocunat"
              height="31"
              width="29"
              src="https://global.cocunat.com/account-black.png"
            />
          </Link>
          <Link
            to={`/${lang}-${country}/cart`}
            style={{ position: "absolute", top: 6, right: 15 }}
          >
            <img
              height="35"
              width="27"
              decoding="async"
              alt="Cart"
              src="https://resizing.cocunat.com/r/?quality=90&fit=contain&height=35&width=27&image=https%3A%2F%2Fglobal.cocunat.com%2Fcart-basket-black.png"
            />
            {cart && (
              <span id="cart-header-number">{cart.number_products}</span>
            )}
          </Link>
        </div>
        <aside id="menu-desktop">
          <div className="container">
            <ul>
              {!isGourmet && region && region.extra && region.extra.club_info && (
                <li>
                  <Link to={`/${lang}-${country}/landing/cocunat-club`}>
                    <strong
                      style={{ fontFamily: "Brown-Bold", color: "#fa8b79" }}
                    >
                      CLUB COCUNAT
                    </strong>
                  </Link>
                </li>
              )}
              <li>
                <Link to={`/${lang}-${country}/search/65`}>{t("face")}</Link>
              </li>
              <li>
                <Link to={`/${lang}-${country}/search/66`}>{t("body")}</Link>
              </li>
              <li>
                <Link to={`/${lang}-${country}/search/67`}>{t("hair")}</Link>
              </li>
              <li>
                <Link to={`/${lang}-${country}/search/90`}>{t("packs")}</Link>
              </li>
              <li>
                <a href={t("trials-link")}>{t("sample")}</a>
              </li>
              <li>
                <Link to={`/${lang}-${country}/search/80`}>C.NOMAKEUP</Link>
              </li>
            </ul>
          </div>
        </aside>
      </header>
      {showMenu && (
        <aside id="menu">
          <button className="close" onClick={() => setShowMenu(false)}>
            x
          </button>
          <p>Bienvenid@</p>
          <ul>
            {!isGourmet && region && region.extra && region.extra.club_info && (
              <li>
                <Link to={`/${lang}-${country}/landing/cocunat-club`}>
                  <strong
                    style={{ fontFamily: "Brown-Bold", color: "#fa8b79" }}
                  >
                    CLUB COCUNAT
                  </strong>
                </Link>
              </li>
            )}
            <li>
              <Link to={`/${lang}-${country}/search/65`}>{t("face")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/66`}>{t("body")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/67`}>{t("hair")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/90`}>{t("packs")}</Link>
            </li>
            <li>
              <a href={t("trials-link")}>{t("sample")}</a>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/80`}>C.NOMAKEUP</Link>
            </li>
          </ul>
        </aside>
      )}
      <img
        width="99999"
        height="99999"
        style={{
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          left: 0,
          width: "99vw",
          height: "99vh",
          maxWidth: "99vw",
          maxHeight: "99vh",
        }}
        alt="-"
        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5OTk5OXB4IiBoZWlnaHQ9Ijk5OTk5cHgiIHZpZXdCb3g9IjAgMCA5OTk5OSA5OTk5OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBzdHJva2U9Im5vbmUiIGZpbGw9Im5vbmUiIGZpbGwtb3BhY2l0eT0iMCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk5OTk5IiBoZWlnaHQ9Ijk5OTk5Ij48L3JlY3Q+IDwvZz4gPC9zdmc+"
      ></img>
    </>
  );
});

export default Header;

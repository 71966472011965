import { t } from "../helpers";
import CountrySelector from "./CountrySelector";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState, useContext, useRef } from "react";
import { CartContext } from "../context/cart";
import { getAllProductsGroupedByCategory } from "../logic/products";
import InputGroup from "../components/InputGroup";
import Button from "../components/Button";

import { addLead } from "../logic/helpers";

const Footer = React.memo(() => {
  let { lang, country } = useParams();
  const { cart, promo, hasLoadedCache, populateCategoriesAndProductsCaches } =
    useContext(CartContext);

  const stateRef = useRef({
    cart: cart,
    promo: promo,
  });
  const setStateRef = (data) => {
    stateRef.current = data;
  };

  let [email, setEmail] = useState("");
  let [emailLoading, setEmailLoading] = useState(false);

  function changeEmail(e) {
    setEmail(e.target.value);
  }

  async function saveLead() {
    let payload = {
      language: lang,
      country: country,
      form_place: "nw_footer_web",
      email: email,
    };
    setEmailLoading(true);
    try {
      let response = await addLead(payload);
      if (response.status === 201) {
        console.log("lead");
        setEmailLoading(false);
        setEmail("");
      }
    } catch (e) {
      console.log(e);
      setEmailLoading(false);
      setEmail("");
    }
  }

  useEffect(() => {
    setStateRef({
      cart: cart,
      promo: promo,
    });
  }, [cart, promo]);

  const lazyLoad = async () => {
    if (!hasLoadedCache) {
      setTimeout(async () => {
        try {
          await populateCategoriesAndProductsCaches(
            lang,
            country,
            stateRef.current.cart.promo_code
          );
        } catch (e) {
          console.log(e);
        }
      }, 1000);
    }
    window.removeEventListener("scroll", lazyLoad);
  };

  useEffect(() => {
    window.addEventListener("scroll", lazyLoad);
  }, []);

  return (
    <section id="footer">
      <div className="pictures">
        <div className="container-wide">
          <div className="title">
            <p style={{ marginBottom: "0px" }}>{t("click_buy")}</p>
          </div>
          <div className="col col-xs-6 col-sm-3">
            <a
              className="content-footer"
              href={`https://cocunat.com/${lang}-${country}/product/boombastic-basica`}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=70&format=webp&fit=cover&image=https://global.cocunat.com/photos/myblueberrynightsblog-amp.jpg`}
                height="280"
                alt="@myblueberrynightsblog"
                loading="lazy"
                width="280"
              />
              <span>@myblueberrynightsblog</span>
            </a>
          </div>
          <div className="col col-xs-6 col-sm-3">
            <a
              className="content-footer"
              href={`https://cocunat.com/${lang}-${country}/product/rizos-cocunat-en-3-pasos`}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=70&format=webp&fit=cover&image=https://global.cocunat.com/photos/glorirovi-amp.jpg`}
                height="280"
                alt="@glorirovi"
                loading="lazy"
                width="280"
              />
              <span>@glorirovi</span>
            </a>
          </div>
          <div className="col col-xs-6 col-sm-3">
            <a
              className="content-footer"
              href={`https://cocunat.com/${lang}-${country}/search/80`}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=70&format=webp&fit=cover&image=https://global.cocunat.com/photos/nat-cebrian-amp.jpg`}
                height="280"
                alt="@nat.cebrian"
                loading="lazy"
                width="280"
              />
              <span>@nat.cebrian</span>
            </a>
          </div>
          <div className="col col-xs-6 col-sm-3">
            <a
              className="content-footer"
              href={`https://cocunat.com/${lang}-${country}/product/the-absolute-crema-antiedad-rejuvenecedora-50ml`}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=70&format=webp&fit=cover&image=https://global.cocunat.com/photos/pau_eche-amp.jpg`}
                height="280"
                alt="@pau_eche"
                loading="lazy"
                width="280"
              />
              <span>@pau_eche</span>
            </a>
          </div>
          <div className="content-icons">
            <a
              href="https://www.instagram.com/cocunat/ "
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/icon-instagram-amp.png`}
                height="50"
                loading="lazy"
                alt="instagram"
                width="50"
                style={{ marginRight: "10px" }}
              />
            </a>
            <a
              href="https://www.facebook.com/Cocunat/"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/icon-facebook-amp.png`}
                height="50"
                alt="facebook"
                loading="lazy"
                width="50"
                style={{ marginLeft: "10px", marginRight: "10px" }}
              />
            </a>
            <a
              href="https://www.tiktok.com/@cocunat?lang=es&is_copy_url=1&is_from_webapp=v1"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/tiktok-icon.png`}
                height="50"
                alt="facebook"
                loading="lazy"
                width="50"
                style={{ marginLeft: "10px" }}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="form leadForm">
        <div
          className="container-cocunat"
          style={{ maxWidth: "650px", margin: "auto" }}
        >
          <p>
            {t("nw_title")}
            {t("nw_text")}
          </p>
          <InputGroup
            id="email"
            name="email"
            title={t("email")}
            className="nw_email"
            value={email}
            onChange={changeEmail}
          />
          <Button
            className="btn-subscribe"
            size="small"
            onClick={saveLead}
            loading={emailLoading}
            style={{ backgroundColor: "black" }}
          >
            {t("nw_button")}
          </Button>
        </div>
      </div>
      <div
        className="container-wide footer-links"
        style={{ overflow: "hidden", padding: "30px 0px" }}
      >
        <div style={{ margin: "10px 0px" }} className="col-xs-12 col-sm-4">
          <p className="title-list">
            <strong>{t("our_products")}</strong>
          </p>
          <ul className="list-footer">
            <li>
              <Link to={`/${lang}-${country}/search/65`}>{t("face")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/66`}>{t("body")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/67`}>{t("hair")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/90`}>{t("packs")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/91`}>{t("sample")}</Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/search/66`}>C.NOMAKEUP</Link>
            </li>
          </ul>
        </div>
        <div style={{ margin: "10px 0px" }} className="col-xs-12 col-sm-4">
          <p className="title-list">
            <strong>{t("security_and_privacy")}</strong>
          </p>
          <ul className="list-footer">
            <li>
              <Link to={`/${lang}-${country}/content/${t("terminos")}`}>
                {t("terms_of_service")}
              </Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/content/${t("devoluciones")}`}>
                {t("delivery_returns_policy")}
              </Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/content/${t("compra")}`}>
                {t("safe_purchase")}
              </Link>
            </li>
            <li>
              <Link to={`/${lang}-${country}/content/${t("politica")}`}>
                {t("privacy_policy")}
              </Link>
            </li>
          </ul>
        </div>
        <div style={{ margin: "10px 0px" }} className="col-xs-12 col-sm-4">
          <p className="title-list">
            <strong>Cocunat</strong>
          </p>
          <ul className="list-footer">
            <li>
              <a href="https://others.cocunat.com/contact">{t("contact")}</a>
            </li>
            <li>
              <a href={t("home-welcome-link")}>{t("about_us")}</a>
            </li>
            <li>
              <a href="https://cocunat.jobs.personio.de/">
                {t("work_with_us")}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="input-group" style={{ width: "250px", margin: "auto" }}>
        <CountrySelector />
      </div>
      <p>&copy; 2022 Cocunat. All Rights Reserved</p>
    </section>
  );
});

export default Footer;

import { createContext, useEffect, useState } from "react";
import { captureException } from "@sentry/browser";
import constants from "../constants";
import { backendCheckCart, getPromo } from "../logic/cart";
import {
  getAllProductsGroupedByCategory,
  getProductById,
  getProductByIds,
} from "../logic/products";
import { Cart } from "@cocunat/cocunat-core-js";
import {
  hasActiveGourmetSubscription,
  userLoggedIn,
  isValidAddress,
} from "../logic/account";
import * as analytics from "../logic/analytics";

const CartContext = createContext(constants.DEFAULT_CART);

function CartContextProvider({ children }) {
  const [cart, setCart] = useState();
  const [paidCart, setPaidCart] = useState();
  const [promo, setPromo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(false);
  const [hasLoadedCache, setHasLoadedCache] = useState(false);
  const [isGourmet, setIsGourmet] = useState(false);
  const [productsCached, setProductsCached] = useState({});
  const [categoriesCached, setCategoriesCached] = useState({});
  const [variationsCached, setVariationsCached] = useState({});
  const [region, setRegion] = useState(null);
  const [showClubModal, setShowClubModal] = useState(true);

  async function _backendCheckCart(_cart) {
    try {
      let result = await backendCheckCart(_cart);
      if (result.status === 200) {
        let cartObject = new Cart({ ...result.data }).getUpdated();
        delete cartObject.used_fallback;
        setCart(cartObject);
      }
    } catch (err) {
      captureException("_backendCheckCart " + err);
      if (err.response && err.response.status === 409) {
        setTimeout(function () {
          window.localStorage.removeItem("cart");
        }, 1500);
      } else {
        if (_cart.updated) {
          let free_shipping =
            region && region.free_shipping ? region.free_shipping : null;
          let shipping_cost =
            region && region.shipping_cost ? region.shipping_cost : 10;
          let cartObject = new Cart({ ..._cart }).fallbackTotals(
            free_shipping,
            shipping_cost
          );
          console.log("Using fallbackTotals");
          cartObject.updated = false;
          cartObject.used_fallback = true;
          setCart(cartObject);
        }
      }
    }
  }

  async function updatePromo(promoCode = null) {
    let promoData;
    promoData = await getPromo(promoCode);
    setPromo(promoData);
  }

  function updateCartAttributes(attributes, callback = () => {}) {
    let cartCopy = { ...cart };
    for (let attributeKey in attributes) {
      cartCopy[attributeKey] = attributes[attributeKey];
      if (attributeKey === "country") {
        cartCopy.address["country"] = attributes[attributeKey];
      }
    }
    cartCopy.updated = true;
    cartCopy.modified_date = new Date().toISOString();

    let user = userLoggedIn();
    let userData = { ...(cartCopy.userData || {}) };
    if (!attributes.hasOwnProperty("userData")) {
      userData.email = cartCopy.address.email;
      userData.first_name = cartCopy.address.first_name;
      userData.last_name = cartCopy.address.last_name;
    }
    userData.user_id = null;
    if (user) {
      userData.user_id = user.id;
    } else {
      userData.user_id = null;
      cartCopy.user_id = null;
    }
    cartCopy.userData = userData;
    setCart(cartCopy);
    callback(cartCopy);
  }

  async function applyCartPromo(promoCode) {
    let cartCopy = { ...cart };
    cartCopy.promo_code = promoCode;
    cartCopy.modified_date = new Date().toISOString();
    cartCopy.promo_code_modified = new Date().toISOString();
    cartCopy.updated = true;
    setCart(cartCopy);
    updatePromo(promoCode);
  }

  function plusOneProduct(
    productId,
    subscriptionType = false,
    isPreCheckout = false
  ) {
    let cartObject = new Cart({ ...cart }).plusOne(
      productId,
      subscriptionType,
      isPreCheckout
    );
    setCart(cartObject);
    if (region) {
      let productData = null;
      for (let i = 0; i < cartObject.orderlines.length; i++) {
        if (cartObject.orderlines[i].id === productId) {
          productData = cartObject.orderlines[i];
          break;
        }
      }
      if (productData) {
        analytics.addToCart(productData, cart, cart.orderlines, region);
      }
    }
  }

  function minusOneProduct(
    productId,
    subscriptionType = false,
    isPreCheckout = false
  ) {
    let cartObject = new Cart({ ...cart }).minusOne(
      productId,
      subscriptionType,
      isPreCheckout
    );
    setIsGourmet(isGourmetInCart(cartObject));
    setCart(cartObject);
  }

  function deleteProduct(
    productId,
    subscriptionType = false,
    isPreCheckout = false
  ) {
    let cartObject = new Cart({ ...cart }).removeProduct(
      productId,
      subscriptionType,
      isPreCheckout
    );
    setIsGourmet(isGourmetInCart(cartObject));
    setCart(cartObject);
  }

  function isGourmetInCart(_cart) {
    if (_cart.cart) {
      _cart = _cart.cart;
    }
    for (let i = 0; i < _cart.orderlines.length; i++) {
      if (
        _cart.orderlines[i].subscription_type &&
        _cart.orderlines[i].subscription_type.includes("GOURMET")
      ) {
        return true;
      }
    }
    return false;
  }

  async function addProductById(
    productId,
    lang,
    country,
    subscriptionType = null
  ) {
    const productToSend = productId;
    if (subscriptionType === "GOURMET-CARD") {
      productId = 53221;
    }
    const response = await getProductById(
      productId,
      lang,
      country,
      cart.promo_code
    );

    if (response.status === 200 && response.data) {
      let productData = response.data;
      let priceDiscounted = null;
      if (subscriptionType === "GOURMET") {
        priceDiscounted = productData.prices.gourmet_product_price;
      } else if (subscriptionType === "GOURMET-CARD") {
        // For GOURMET-CARD we display gourmet experience info but we need to override product_id and subscription_type
        productData.id = productToSend;
        // regular subscription price is used because 53221 has regular price defined
        priceDiscounted = productData.prices.regular_subscription_price;
      }
      addProduct(productData, priceDiscounted, subscriptionType);
    }
  }

  async function addProductByIds(productIds, lang, country) {
    const response = await getProductByIds(
      productIds.toString(),
      lang,
      country,
      cart.promo_code
    );

    if (response.status === 200 && response.data) {
      const productsData = response.data;
      let cartObject = new Cart({ ...cart }).addMultipleProducts(productsData);
      setCart(cartObject);
      if (region) {
        for (let i = 0; i < productsData.length; i++) {
          analytics.addToCart(productsData[i], cart, cart.orderlines, region);
        }
      }
    }
  }

  function addProduct(
    productData,
    priceDiscounted = null,
    subscriptionType = false,
    subscriptionInterval = null,
    isPreCheckout = false
  ) {
    let cartObject = new Cart({ ...cart }).addProduct(
      productData,
      priceDiscounted,
      subscriptionType,
      subscriptionInterval,
      isPreCheckout
    );
    setCart(cartObject);
    if (subscriptionType && subscriptionType.includes("GOURMET")) {
      setIsGourmet(true);
    }
    if (region) {
      analytics.addToCart(productData, cart, cart.orderlines, region);
    }
  }

  function markCartAsPaid() {
    if (cart) {
      if (paidCart) {
        sessionStorage.removeItem("paid_cart");
      }
      setPaidCart(cart);
      sessionStorage.setItem("paid_cart", JSON.stringify(cart));
      localStorage.removeItem("cart");
      emptyCart();
    }
  }

  function fillEmptyAddress(address) {
    let savedPreviousAddress = localStorage.getItem("address");
    let email = "";
    if (address && address.email && address.email.length > 0) {
      email = address.email;
    }

    const user = userLoggedIn();
    if (user && user.email) {
      email = user.email;
    }

    if (savedPreviousAddress) {
      savedPreviousAddress = JSON.parse(savedPreviousAddress);
      address.first_name =
        address.first_name || savedPreviousAddress.first_name;
      address.last_name = address.last_name || savedPreviousAddress.last_name;
      address.email = email || savedPreviousAddress.email;
      address.address = address.address || savedPreviousAddress.address;
      address.zip_code = address.zip_code || savedPreviousAddress.zip_code;
      address.city = address.city || savedPreviousAddress.city;
      address.prefix_phone =
        address.prefix_phone || savedPreviousAddress.prefix_phone;
      address.phone = address.phone || savedPreviousAddress.phone;
      address.country = address.country || savedPreviousAddress.country;
      address.lang = address.lang || savedPreviousAddress.lang;
    }
    return address;
  }

  function emptyCart() {
    let cartObject = new Cart(null).initializeCart();
    updatePromo(cartObject.promo_code);
    cartObject.address = fillEmptyAddress(cartObject.address);
    setCart(cartObject);
  }

  function updateProductsCached(products) {
    let productsCopy = { ...productsCached };
    for (let product of products) {
      if (product.translation) {
        productsCopy[product.translation.slug] = product;
      }
    }
    setProductsCached(productsCopy);
  }

  async function populateCategoriesAndProductsCaches(
    lang,
    country,
    promo_code = ""
  ) {
    let response = await getAllProductsGroupedByCategory(
      lang,
      country,
      promo_code
    );
    if (response.status === 200) {
      let categories = {
        65: [],
        66: [],
        67: [],
        80: [],
        90: [],
        91: [],
        "pre-checkout": [],
        "happy-hair": [],
        default: [],
      };
      let productsSlugs = {};
      let categoryMap = response.data.categories;
      let products = response.data.products;

      for (let i = 0; i < products.length; i++) {
        let product = products[i];

        if (categoryMap["65"].includes(product["id"])) {
          categories[65].push(product);
        }
        if (categoryMap["66"].includes(product["id"])) {
          categories[66].push(product);
        }
        if (categoryMap["67"].includes(product["id"])) {
          categories[67].push(product);
        }
        if (categoryMap["80"].includes(product["id"])) {
          categories[80].push(product);
        }
        if (categoryMap["90"].includes(product["id"])) {
          categories[90].push(product);
        }
        if (categoryMap["91"].includes(product["id"])) {
          categories[91].push(product);
        }
        if (categoryMap["pre-checkout"].includes(product["id"])) {
          categories["pre-checkout"].push(product);
        }
        if (categoryMap["happy-hair"].includes(product["id"])) {
          categories["happy-hair"].push(product);
        }

        categories["default"].push(product);

        productsSlugs[product.translation.slug] = product;
      }
      setCategoriesCached(categories);
      setProductsCached(productsSlugs);
      setHasLoadedCache(true);
      console.log("Catalog cached");
    }
  }

  function updateCategoriesCached(categoryId, category) {
    let categoriesCopy = { ...categoriesCached };
    categoriesCopy[categoryId] = category;
    setCategoriesCached(categoriesCopy);
  }

  function updateVariationsCached(productId, variations) {
    let variationsCopy = { ...variationsCached };
    variationsCopy[productId] = variations;
    setVariationsCached(variationsCopy);
  }

  function hasSubscription(subscriptionType = null) {
    return new Cart({ ...cart }).hasSubscription(subscriptionType);
  }

  function hasTrial() {
    return new Cart({ ...cart }).hasTrial();
  }

  function hasPreCheckout(productId = null) {
    return new Cart({ ...cart }).findProductIndex(productId, false, true) >= 0;
  }

  function hasProduct(productId) {
    let cartObject = new Cart({ ...cart });
    return (
      cartObject.findProductIndex(productId, false, false, null, true) >= 0
    );
  }

  function setLoggedUserData() {
    const user = userLoggedIn();
    if (user) {
      (async () => {
        const isGourmetUser = await hasActiveGourmetSubscription(user.email);
        if (!isGourmet) {
          setIsGourmet(isGourmetUser);
        }
      })();
    }
  }

  useEffect(() => {
    setLoading(true);
    const cartData = JSON.parse(localStorage.getItem("cart"));
    setLoggedUserData();
    if (cartData) {
      /*if (!cartData.promo_code) {
        cartData.promo_code = "SPRING20";
        cartData.promo_code_modified = new Date().toISOString();
      }*/
      let cartObject = new Cart({ ...cartData });
      if (cartObject.isPromoCodeOld()) {
        sessionStorage.removeItem("promo");
        setCart(cartObject.removePromo());
      } else {
        updatePromo(cartData.promo_code);
        setCart(cartData);
      }
      if (!isGourmet) {
        setIsGourmet(isGourmetInCart(cartObject));
      }
    } else {
      emptyCart();
    }

    const paidCartData = JSON.parse(sessionStorage.getItem("paid_cart"));
    if (paidCartData) {
      setPaidCart(paidCartData);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (cart) {
      localStorage.setItem("cart", JSON.stringify(cart));
      if (cart && cart.address && isValidAddress(cart.address)) {
        localStorage.setItem("address", JSON.stringify(cart.address));
      }
      if (cart.updated) {
        _backendCheckCart(cart);
      }
    }
  }, [cart]);

  useEffect(() => {
    if (language) {
      updateCartAttributes({ language: language });
    }
    setProductsCached({});
  }, [language]);

  useEffect(() => {
    if (promo) {
      sessionStorage.setItem("promo", JSON.stringify(promo));
    } else {
      sessionStorage.removeItem("promo");
    }
    setProductsCached({});
    setCategoriesCached({});
  }, [promo]);

  return (
    <CartContext.Provider
      value={{
        cart,
        promo,
        loading,
        language,
        productsCached,
        categoriesCached,
        isGourmet,
        region,
        hasLoadedCache,
        setHasLoadedCache,
        setRegion,
        setLanguage,
        setCart,
        plusOneProduct,
        minusOneProduct,
        deleteProduct,
        addProduct,
        updateCartAttributes,
        markCartAsPaid,
        updateProductsCached,
        updateCategoriesCached,
        setCategoriesCached,
        setProductsCached,
        paidCart,
        applyCartPromo,
        hasSubscription,
        hasPreCheckout,
        hasProduct,
        hasTrial,
        addProductById,
        addProductByIds,
        showClubModal,
        setShowClubModal,
        setLoggedUserData,
        populateCategoriesAndProductsCaches,
        updateVariationsCached,
        variationsCached,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export { CartContextProvider, CartContext };

import PriceWithSymbol from "./PriceWithSymbol";

function Price({ prices, discounted, showGourmetDiscount = false }) {
  return (
    <>
      {prices &&
      prices.discounted &&
      prices.price !== prices.discounted &&
      !showGourmetDiscount ? (
        <span className="price-product">
          <PriceWithSymbol
            amount={prices.price}
            format={prices.format}
            symbol={prices.symbol}
            spanClassName={"strike-price"}
          />
          <PriceWithSymbol
            amount={prices.discounted}
            format={prices.format}
            symbol={prices.symbol}
            spanClassName={"actual-price"}
          />
        </span>
      ) : (
        prices && (
          <>
            {showGourmetDiscount ? (
              <span className="price-product">
                <PriceWithSymbol
                  amount={
                    prices.gourmet_product_price
                      ? prices.gourmet_product_price
                      : prices.discounted
                  }
                  format={prices.format}
                  symbol={prices.symbol}
                  spanClassName={"actual-price"}
                />
              </span>
            ) : (
              <span className="price-product">
                <PriceWithSymbol
                  amount={prices.price}
                  format={prices.format}
                  symbol={prices.symbol}
                  spanClassName={"actual-price"}
                />
              </span>
            )}
          </>
        )
      )}
    </>
  );
}

export default Price;

import { t } from "../../helpers";
import Price from "../Price";
import RatingStars from "../RatingStars";
import React from "react";

const ProductMainTitle = React.memo(({ product }) => {
  console.log("PRINGINT TITLE", product.prices);
  return (
    <>
      <div id="title">
        <h1>{product.translation.name}</h1>
        <h2>{product.translation.tagline}</h2>
      </div>
      <p id="rating" style={{ textAlign: "left", fontSize: 15, margin: 0 }}>
        <small>
          <RatingStars rating={product.rating} size={15} />
          <span style={{ marginLeft: "5px", color: "black" }}>
            {product.comments_summary ? product.comments_summary.num : 0}{" "}
            <a href="#comments" style={{ color: "black" }}>
              {" "}
              {t("comments")}
            </a>
          </span>
        </small>
      </p>
    </>
  );
});

export default ProductMainTitle;

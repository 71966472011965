import { buildPhotoUrl } from "../helpers";
import React, { useContext, useEffect } from "react";
import { CartContext } from "../context/cart";

function PromoSticker({
  discount_applied,
  rendered_from,
  showGourmet = null,
  lang = null,
}) {
  const { promo } = useContext(CartContext);

  return (
    <>
      {promo && promo.stickers && promo.stickers[discount_applied] && (
        <img
          src={buildPhotoUrl(promo.stickers[discount_applied])}
          alt={discount_applied}
          className={`promo-sticker-${rendered_from}`}
        />
      )}
    </>
  );
}

export default PromoSticker;

import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../context/cart";
import { useParams, Redirect } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import CartLine from "../components/CartLine";
import Modal from "../components/Modal";
import Button from "../components/Button";
import Carousel from "./Carousel";
import { getProductsByCategory } from "../logic/products";
import ProductLoop from "./product/ProductLoop";
import "../css/CartModal.css";
import { Cart } from "@cocunat/cocunat-core-js";
import { t } from "../helpers";

function AddToCartModal(props) {
  const { lang, country } = useParams();
  const [email, setEmail] = useState("");
  const { cart, categoriesCached, promo } = useContext(CartContext);
  let [products, setProducts] = useState([]);
  let [redirectTo, setRedirectTo] = useState(null);
  let [orderline, setOrderline] = useState(null);

  const goCart = () => {
    setRedirectTo(`/${lang}-${country}/cart`);
  };

  function findProductIndex(productId, subscriptionType = false) {
    let cartObject = new Cart({ ...cart });
    return cartObject.findProductIndex(productId, subscriptionType);
  }
  useEffect(() => {
    const fetchData = async () => {
      let products = [];
      let _promo = promo ? promo.code : "";
      try {
        products = await getProductsByCategory(
          "default",
          lang,
          country,
          _promo
        );
        let productsData = products.data
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);
        setProducts(productsData);
      } catch (err) {
        setProducts([]);
        console.log(err);
      }
    };

    if (!orderline && props.addedProduct && cart && cart.orderlines) {
      let subscriptionType = props.addedProduct.subscriptionType
        ? props.addedProduct.subscriptionType
        : false;
      console.log(props.addedProduct.id, subscriptionType);
      let productIndex = findProductIndex(
        props.addedProduct.id,
        subscriptionType
      );
      console.log(props.addedProduct);
      console.log(productIndex);
      let _orderline = cart.orderlines[productIndex];
      setOrderline(_orderline);
    }
    if (categoriesCached.hasOwnProperty("default")) {
      let categoryProducts = categoriesCached["default"];
      categoryProducts = categoryProducts
        .sort(() => 0.5 - Math.random())
        .slice(0, 10);
      setProducts(categoryProducts);
    } else {
      fetchData();
    }
  }, []);

  if (redirectTo) {
    return <Redirect push to={redirectTo} />;
  }
  return (
    <>
      <Modal isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
        <section className="addToCartModal" id="ModalUpsellAddtocard">
          <h2 className="addTo">{t("modal_atc_text1")}</h2>
          <section className="linesAndButtons">
            {cart && cart.orderlines && (
              <section className="cartLines" style={{ marginBottom: 16 }}>
                <div
                  className="orderlines-list"
                  style={{ marginTop: 20, padding: "0px 15px" }}
                >
                  {orderline && <CartLine orderline={orderline} />}
                </div>
              </section>
            )}
            <section className="buttons">
              <div>
                <Button
                  size="big"
                  type="primary"
                  width="wide"
                  style={{ backgroundColor: "black" }}
                  onClick={() => {
                    props.setIsOpen(false);
                  }}
                >
                  {t("modal_atc_btn1")}
                </Button>
              </div>
              <div>
                <Button
                  size="big"
                  type="outlined"
                  width="wide"
                  onClick={() => {
                    goCart();
                  }}
                >
                  {" "}
                  {t("modal_atc_btn2")}{" "}
                </Button>{" "}
              </div>
            </section>
          </section>

          {cart &&
            cart.totals &&
            (cart.totals.percentage_for_free_shipping > 0 ||
              (cart.totals &&
                cart.totals.rewards &&
                cart.totals.rewards.next_reward_text &&
                !cart.totals.percentage_for_free_shipping)) && (
              <section className="progressBar" style={{ marginTop: 32 }}>
                {cart.totals && cart.totals.percentage_for_free_shipping > 0 && (
                  <div className="free-shipping-progressbar">
                    <ProgressBar
                      completed={cart.totals.percentage_for_free_shipping}
                      amount={cart.totals.amount_for_free_shipping}
                      currency={cart.totals.currency}
                      type="free_shipping"
                    />
                  </div>
                )}
                {cart.totals &&
                  cart.totals.rewards &&
                  cart.totals.rewards.next_reward_text &&
                  !cart.totals.percentage_for_free_shipping && (
                    <div className="free-shipping-progressbar">
                      <ProgressBar
                        completed={
                          cart.totals.rewards.percentage_for_next_reward
                        }
                        amount={cart.totals.rewards.amount_for_next_reward}
                        currency={cart.totals.currency}
                        type="next_reward"
                      />
                    </div>
                  )}
              </section>
            )}
          {products && products.length > 0 && (
            <section className="recommendedProducts" style={{ marginTop: 16 }}>
              <h2>{t("modal_atc_text2")}</h2>
              <section
                className="product-list-mobile"
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginTop: 16,
                }}
              >
                {products.length > 0 && (
                  <Carousel
                    element="carousel-products-mobile"
                    options={{
                      startAt: 1,
                      perView: 1,
                      autoplay: 200000,
                      gap: 20,
                      type: "carousel",
                      peek: 50,
                    }}
                  >
                    {products.map((product, i) =>
                      product && product.prices ? (
                        <ProductLoop
                          product={product}
                          key={i}
                          featured
                          actionFrom="AddToCartModal"
                        />
                      ) : (
                        <></>
                      )
                    )}
                  </Carousel>
                )}
              </section>
              <section
                className="product-list-desktop"
                style={{
                  marginLeft: "-15px",
                  marginRight: "-15px",
                  marginTop: 20,
                }}
              >
                <Carousel
                  element="carousel-products-desktop"
                  options={{
                    startAt: 0,
                    perView: 3,
                    autoplay: false,
                    gap: 0,
                    type: "carousel",
                    peek: 0,
                  }}
                >
                  {products.map((product, i) =>
                    product && product.prices ? (
                      <ProductLoop
                        product={product}
                        key={i}
                        featured
                        actionFrom="AddToCartModal"
                      />
                    ) : (
                      <></>
                    )
                  )}
                </Carousel>
              </section>
            </section>
          )}
        </section>
      </Modal>
    </>
  );
}

export default AddToCartModal;
